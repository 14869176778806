import React, { useContext } from "react";

import styles from "../../../Styles/Common/AreYouSure.module.css";
import LanguageContext from "../../../Context/LanguageContext";

const AreYouSure = ({ title, description, confirm, close }) => {
    const { language } = useContext(LanguageContext);

    // ==================================================================

    return (
        <div className="modal-wrapper">
            <div className="close-model-div" onClick={close}></div>
            <div className={styles.modalBox}>
                <div className={styles.informationContainer}>
                    <div className={styles.titleContainer}>
                        <img src="/imgs/Common/deleteIcon.svg" alt="delete icon" />
                        <h3>{title}</h3>
                    </div>
                    {description ? <p>{description}</p> : null}
                </div>
                <div className={styles.btnContainer}>
                    <button className={styles.closeBtn} onClick={close}>
                        {language.cancle}
                    </button>
                    <button className={styles.confirmBtn} onClick={confirm}>
                        {language.delete}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AreYouSure;
