import React, { useContext, useEffect, useState } from "react";

import styles from "../Styles/Personel/Personel.module.css";
import Header from "../Components/Common/Header";
import LanguageContext from "../Context/LanguageContext";
import Toast from "../Components/Common/Toast";

import Grid from "../Components/Common/Grid/Grid";
import LotteryWinnerModal from "../Components/Winner/LotteryWinnerModal"
import { winnerclass, ceremony } from '../Connection/Transaction'
import AuthContext from "../Context/AuthContext";
const Winner = (props) => {
    const { language } = useContext(LanguageContext);
    const { code, phone } = useContext(AuthContext);

    // ======================================================================

    const [personelsList, setPersonelsList] = useState([]);
    const [gridConfig, setGridConfig] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [newPersonelModal, setNewPersonelModal] = useState("");
    const [selectedForDelete, setSelectedForDelete] = useState({});
    const [selectbox, setselectbox] = useState({});

    // ======================================================================


    // ======================================================================

    useEffect(() => {
        ceremony.Getall(phone, code).then(res => {
            if (res[0]) {
                if (res[2].length > 0) {
                    setselectbox(res[2])
                    winnerclass.Getall(phone, code, res[2][0].id).then(res => {
                        if (res[0]) {
                            setPersonelsList(res[2])
                        }
                        else
                            Toast("error", res[1]);

                    })
                }
            }
            else
                Toast("error", res[1]);

        })


        // setPersonelsList([
        //     {
        //         id: 1,
        //         name: "test",
        //         startDate: "1402/11/5",
        //         startTime: "14:19",
        //         endDate: "1402/11/6",
        //         endTime: "14:19",
        //         tiket: "H10"
        //     }

        // ]);
        setGridConfig({
            column: [
                {
                    name: "id",
                    title: "ردیف",
                    type: "text",
                    data: "",
                    editable: true,
                    hidden: false,
                    search: true,
                    size: "100px",
                },
                {
                    name: "name",
                    title: "نام",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                },
                {
                    name: "family",
                    title: "نام خانوادگی",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "160px",
                },
                {
                    name: "phone",
                    title: "شماره همراه",
                    type: "text",
                    data: "",
                    editable: true,
                    hidden: false,
                    search: true,
                    size: "150px",
                },
                {
                    name: "index",
                    title: "رتبه",
                    type: "text",
                    data: "",
                    editable: true,
                    hidden: false,
                    search: true,
                    size: "100px",
                }, {
                    name: "giftName",
                    title: "جایزه",
                    type: "text",
                    data: "",
                    editable: true,
                    hidden: false,
                    search: true,
                    size: "300px",
                }

            ],
            setting: {
                edit: false,
                delete: false,
                search: false,
                add: false,
                select: false,
            },
        });
    }, []);
    const handleclose = () => {
        if (selectbox.length > 0) {
            winnerclass.Getall(phone, code, selectbox[0].id).then(res => {
                if (res[0]) {

                    setPersonelsList(res[2])
                }

                else
                    Toast("error", res[1]);

            })
        }
        setNewPersonelModal(false)
    }
    const ChangeSelectbox = (val) => {
        winnerclass.Getall(phone, code, val).then(res => {
            if (res[0]) {
                setPersonelsList(res[2])
            }
            else
                Toast("error", res[1]);

        })
    }
    // ======================================================================

    return (
        <div className={styles.wrapper}>
            <Header
                pageTitle="برندگان"
                newItemTitle="برنده "
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleNewBtn={() => setNewPersonelModal(true)}
            />

            <select className={styles.selectBox} onChange={(e) => ChangeSelectbox(e.target.value)}>
                {selectbox.length > 0 ? selectbox.map(x =>
                    (<option value={x.id}>{x.name}</option>))
                    : ""
                }
            </select>

            <div className={styles.contentContainer}>
                <Grid
                    data={personelsList}
                    config={gridConfig}
                    setSelectedForDelete={setSelectedForDelete}
                    itemInPerPage={15}
                    search={searchValue}
                    setSearch={setSearchValue}
                />
            </div>
            {newPersonelModal ? <LotteryWinnerModal close={() => handleclose()} /> : null}

        </div>
    );
};

export default Winner;
