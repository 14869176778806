export default class Url {
    // ============================================ Users ==============================================

    //static get baseurl() { return "https://185.8.175.184/api/api" }
    //static get baseurl() { return "https://localhost:7209/api" }
    static get baseurl() { return "https://tadabborzendegi.ir/api/api" }

    static get LoginAnswer() { return this.baseurl + "/Person/Login" }
    static get RigesterAnswer() { return this.baseurl + "/Person/Rigister" }
    static get SendAnswer() { return this.baseurl + "/Person/UserAnswers" }
   
    static get Hadis() { return this.baseurl + "/Hadis/Get" }
    static get RegisterUser() { return this.baseurl + "/Register/RegisterTiket" }
    static get RegisterInfo() { return this.baseurl + "/Register/RegisterUserInfo" }
    static get adminLogin() { return this.baseurl + "/LoginAdmin/LoginAdmin" }
    static get CermonyGetall() { return this.baseurl + "/Cermony/GetAll" }
    static get CermonyGetallByType() { return this.baseurl + "/Cermony/GetAllByType" }
    static get Cermonypost() { return this.baseurl + "/Cermony/AddNew" }
    static get Cermonyupdate() { return this.baseurl + "/Cermony/Update" }
    static get Cermonypostgroup() { return this.baseurl + "/Cermony/AddNewgroup" }
    static get Cermonydelete() { return this.baseurl + "/Cermony/Delete" }
    static get PersonGetAll() { return this.baseurl + "/Person/GetAll" }
    static get LotteryGetAll() { return this.baseurl + "/Lottery/GetAll" }
    static get LotteryAdd() { return this.baseurl + "/Lottery/AddNew" }
    static get LotteryDelete() { return this.baseurl + "/Lottery/Delete" }
    static get GiftGetAll() { return this.baseurl + "/Gift/GetAll" }
    static get GiftAdd() { return this.baseurl + "/Gift/AddNew" }
    static get GiftDelete() { return this.baseurl + "/Gift/Delete" }
    static get winerGetAll() { return this.baseurl + "/Winner/GetAll" }
    static get WinneVin() { return this.baseurl + "/Winner/WinneVin" }
    static get UserLogin() { return this.baseurl + "/Profile/Loginprofile" } 
    static get VerifyUser() { return this.baseurl + "/Profile/GetProfile" }

    static get GetAllQuestion() { return this.baseurl + "/Question/GetAllQuestions" }
    static get InsertQuestion() { return this.baseurl + "/Question/InsertQuestions" }
    static get DeleteQuestion() { return this.baseurl + "/Question/DeleteQuestion" }
    static get UpdateQuestion() { return this.baseurl + "/Question/UpdateQuestions" }
    static get GetAllCeremonyQuestion() { return this.baseurl + "/Question/GetCermonyQuestions" }
    static get DeleteCermonyQuestion() { return this.baseurl + "/Question/DeleteQuestionFromCermony" }
    static get AddCermonyQuestion() { return this.baseurl + "/Question/InsertQuestionsToCermony" }

    // ============================================ Main ==============================================

    static get Main() { return "Main" }

    static get Get_Main() { return this.Main + "/get" }
    static get Insert_Main() { return this.Main + "/insert/body" }
    static get Update_Main() { return this.Main + "/update/body" }
    static get Delete_Main() { return this.Main + "/delete" }

    static get Insert_Form_Main() { return this.Main + "/insert" }
    static get Update_Form_Main() { return this.Main + "/update" }

    // ============================================ Backend Pages ==============================================

    static get ScreenGroup() { return "ScreenGroup" }
}