import React, { useContext, useEffect, useState } from "react";

import styles from "../Styles/Personel/Personel.module.css";
import Header from "../Components/Common/Header";
import LanguageContext from "../Context/LanguageContext";
import AreYouSure from "../Components/Modal/Common/AreYouSure";
import Toast from "../Components/Common/Toast";

import Grid from "../Components/Common/Grid/Grid";
import AddNewGiftModal from "../Components/Gifts/AddGiftModal"
import { giftclass,ceremony } from '../Connection/Transaction'
import AuthContext from "../Context/AuthContext";
const Gifts = (props) => {
    const { language } = useContext(LanguageContext);
    const { code, phone } = useContext(AuthContext);

    // ======================================================================

    const [personelsList, setPersonelsList] = useState([]);
    const [gridConfig, setGridConfig] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [newPersonelModal, setNewPersonelModal] = useState("");
    const [selectedForDelete, setSelectedForDelete] = useState({});
    const [selectbox, setselectbox] = useState({});

    // ======================================================================

    const handleDeleteItem = () => {
        setPersonelsList((prevState) => prevState.filter((item) => item.id != selectedForDelete.id));
        giftclass.Deletedata(selectedForDelete.id, phone, code).then(res => {
            if (res[0]) {
                Toast("success", res[1]);
            }
            else
                Toast("error", res[1]);
        })
        setSelectedForDelete({});
    };

    // ======================================================================

    useEffect(() => {
        ceremony.Getall(phone, code).then(res => {
            console.log(res)
            if (res[0]) {
                if (res[2].length > 0) {
                    setselectbox(res[2])
                    giftclass.Getall(phone, code, res[2][0].id).then(res => {
                        console.log(res)
                        if (res[0]) {
                            setPersonelsList(res[2])
                        }
                        else
                            Toast("error", res[1]);

                    })
                }
            }
            else
                Toast("error", res[1]);

        })


        // setPersonelsList([
        //     {
        //         id: 1,
        //         name: "test",
        //         startDate: "1402/11/5",
        //         startTime: "14:19",
        //         endDate: "1402/11/6",
        //         endTime: "14:19",
        //         tiket: "H10"
        //     }

        // ]);
        setGridConfig({
            column: [
                {
                    name: "id",
                    title: "ردیف",
                    type: "text",
                    data: "",
                    editable: true,
                    hidden: false,
                    search: true,
                    size: "100px",
                },
                {
                    name: "name",
                    title: "نام",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                },
                {
                    name: "count",
                    title: "تعداد",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "160px",
                },
                {
                    name: "index",
                    title: "رتیه",
                    type: "text",
                    data: "",
                    editable: true,
                    hidden: false,
                    search: true,
                    size: "100px",
                },

            ],
            setting: {
                edit: false,
                delete: true,
                search: false,
                add: false,
                select: false,
            },
        });
    }, []);
    const handleclose = () => {
        if (selectbox.length > 0) {
            giftclass.Getall(phone, code, selectbox[0].id).then(res => {
                if (res[0]) {
                    setPersonelsList(res[2])
                }
                else
                    Toast("error", res[1]);

            })
        }
        setNewPersonelModal(false)
    }
    const ChangeSelectbox = (val) => {
        giftclass.Getall(phone, code, val).then(res => {
            if (res[0]) {
                setPersonelsList(res[2])
            }
            else
                Toast("error", res[1]);

        })
    }

    // ======================================================================

    return (
        <div className={styles.wrapper}>
            <Header
                pageTitle="لیست جوایز"
                newItemTitle="جایزه"
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleNewBtn={() => setNewPersonelModal(true)}
            />

            <select className={styles.selectBox} onChange={(e) => ChangeSelectbox(e.target.value)}>
                {selectbox.length > 0 ? selectbox.map(x => (<option value={x.id}>{x.name}</option>))
                    : ""
                }
            </select>

            <div className={styles.contentContainer}>
                <Grid
                    data={personelsList}
                    config={gridConfig}
                    setSelectedForDelete={setSelectedForDelete}
                    itemInPerPage={15}
                    search={searchValue}
                    setSearch={setSearchValue}
                />
            </div>

            {newPersonelModal ? <AddNewGiftModal close={() => handleclose()} /> : null}
            {selectedForDelete.id ? (
                <AreYouSure
                    title={language.delete_modal_title.replace("{name}", "جایزه")}
                    description={language.delete_modal_description.replace("{name}", `${selectedForDelete.name}`)}
                    confirm={handleDeleteItem}
                    close={() => setSelectedForDelete({})}
                />
            ) : null}
        </div>
    );
};

export default Gifts;
