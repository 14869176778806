import React, { useState, useRef, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Toast from "../Components/Common/Toast";
import LoadingContext from "../Context/LoadingContext";
import AuthenticatingLayout from "../Container/Layout/AuthenticatingLayout";
import styles from "../Styles/Authenticating/EnterPhone.module.css";
import { Register } from '../Connection/Transaction'
import { Hadis } from '../Connection/Transaction'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, FreeMode, EffectFade } from 'swiper/modules';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
const EnterPhone = () => {
    const { setLoading } = useContext(LoadingContext);
    const navigate = useNavigate();
    const location = useLocation();

    const simpleValidator = useRef(
        new SimpleReactValidator({
            messages: {
                required: "پر کردن این فیلد الزامی می‌باشد!",
                min: "این فیلد حداقل باید شامل 11 کاراکتر باشد.",
                max: "این فیلد حداکثر باید شامل 11 کاراکتر باشد.",
                phone: "شماره تلفن وارد شده معتبر نمی‌باشد.",
            },
            element: (msg) => (
                <div className="validator-error-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width={20} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ff2c56">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
                        />
                    </svg>
                    <span className="validator-error-text">{msg}</span>
                </div>
            ),
        })
    );

    // =======================================================================

    const [, forceUpdate] = useState();
    const [phoneNumber, setPhoneNumber] = useState(location.state?.phoneNumber ? location.state.phoneNumber : "");
    const [code, setcode] = useState(location.state?.code ? location.state.code : "");
    const [registerok, setregisterok] = useState(false);

    const [hadis, sethadis] = useState([]);

    const convert=(value)=> 
    {
        var persian = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
        var arabic = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
        var englis =['0','1','2','3','4','5','6','7','8','9'];
        
        for(let i=0;i<persian.length;i++)
        {
            value = value.replace(persian[i],englis[i]);
            value = value.replace(arabic[i],englis[i]);
        }
        return value;
    }

    // =======================================================================

    const submitForm = (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            setLoading(true);

            var ncode=convert(code);
            navigate("/UserAnswer", { state: { phoneNumber } });
            setLoading(false);

            // Register.Login(phoneNumber, ncode).then(res => {

            //     console.log(res)
            //     if (res[0]==200) {
            //         let name = res[2].name;
            //         let family = res[2].family;
            //         navigate("/userinfo", { state: { phoneNumber, code, name, family } });

            //         Toast("success", "کد با موفقیت ارسال شد!");
            //     }
            //     else if(res[0]==100){
            //         setregisterok(true);
            //     }
            //     else {
            //         Toast("error", res[1]);
            //     }
            //     setLoading(false);

            // })
        } else {
            Toast("error", "لطفا فرم را به درستی کامل کن!");
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    // =======================================================================

    useEffect(()=>{
        // Hadis.Get().then(res => {
        //     console.log(res);
        //     sethadis([res.masoumTitle,res.bookTitle,res.hadithTextPersian]);
        // });
    },[]);

    return (
        <AuthenticatingLayout>
            <div className={styles.AuthenticatingHeader}>
            </div>
            {!registerok?( <div className={styles.AuthenticatingSection}>
                <div className={styles.AuthenticatingBody}>
                    <form className={styles.form} onSubmit={submitForm} autoComplete={null}>
                        <div className={styles.formSection}>
                            <label htmlFor="phoneNumber" className={styles.formLabel}>
                                شماره همراه خود را وارد کنید
                            </label>
                            <input
                                autoFocus
                                type="tel"
                                name="phoneNumber"
                                className={styles.formInput}
                                value={phoneNumber}
                                autoComplete={null}
                                placeholder="0912xxxxxxx"
                                onChange={(e) => {
                                    setPhoneNumber(e.target.value);
                                    simpleValidator.current.showMessageFor("phoneNumber");
                                }}
                            />
                            {simpleValidator.current.message("phoneNumber", phoneNumber, "required|phone|min:11|max:11")}
                        </div>
                        <div className={styles.formSection}>
                            <label htmlFor="code" className={styles.formLabel}>
                                کد قرعه کشی را وارد کنید
                            </label>
                            <input
                                type="text"
                                name="code"
                                className={styles.formInput}
                                value={code}
                                autoComplete={null}
                                placeholder="AH12011DF"
                                onChange={(e) => {
                                    setcode(e.target.value);
                                    simpleValidator.current.showMessageFor("code");

                                }}
                            />
                            {simpleValidator.current.message("code", code, "required")}
                        </div>

                        <button type="submit" className={styles.formBtn}>
                            ارسال کد تائید
                        </button>
                    </form>

                </div>
            </div>):(
                   <div>
                   <span className={styles.spansuccess}>ثبت نام شما با موفقیت انجام شد</span>
               </div>
            )}
           
            <div className={styles.AuthenticatingBottom}>

                <Swiper
                   
                    loop={true}
                    autoplay={{
                        delay: 0
                    }}
                    modules={[Autoplay, Navigation]}
                    className={styles.contentWrapperSwiper}
                    speed={2000}
                    slidesPerView={4}
                >

                    <SwiperSlide>
                        <a href="https://tadabborpub.ir/">
                        <img src="./imgs/imgs/slide1.png" className={styles.imgslide} /></a>
                    </SwiperSlide>
                    <SwiperSlide>
                    <a href="https://alisaboohi.com/">
                        <img src="./imgs/imgs/slide3.jpg" className={styles.imgslide} /></a>
                    </SwiperSlide>
                    <SwiperSlide>
                    <a href="http://ganjineh-tadabbor.ir/">
                        <img src="./imgs/imgs/slide4.jpg" className={styles.imgslide} /></a>
                    </SwiperSlide>
                    <SwiperSlide>
                    <a href="https://nehzattadabor.ir/">
                        <img src="./imgs/imgs/slide5.jpg" className={styles.imgslide} /></a>
                    </SwiperSlide>
                    <SwiperSlide>
                    <a href="https://tadaborquran.com/">
                        <img src="./imgs/imgs/slide6.jpg" className={styles.imgslide} /></a>
                    </SwiperSlide>
                </Swiper>
                
                <a href="https://nehzattadabor.com/cultural" className={styles.linkstyle}>نذر فرهنگی</a>
                <span className={styles.spantitle}>{hadis[0]}</span>
                <span className={styles.spantitle}>{hadis[2]}</span>
                <span className={styles.spanfooter}>{hadis[1]}</span>
            </div>
        </AuthenticatingLayout>
    );
};

export default EnterPhone;
