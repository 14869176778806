import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";
import LanguageContext from "../../Context/LanguageContext";
import LoadingContext from "../../Context/LoadingContext";
import AuthContext from "../../Context/AuthContext";

import styles from "../../Styles/Personel/AddNewPersonelModal.module.css";
import Toast from "../Common/Toast";
import { QuestionsClass } from '../../Connection/Transaction'
const EditQuestionModal = ({ close,EditData }) => {
    const { code, phone } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);
    const { setLoading } = useContext(LoadingContext);
console.log(EditData)
    // ======================================================================

    const [title, settitle] = useState(EditData?.title);
    const [score, setscore] = useState(EditData?.score);
    const [awnser1ID, setawnser1ID] = useState();
    const [awnser2ID, setawnser2ID] = useState();
    const [awnser3ID, setawnser3ID] = useState();
    const [awnser4ID, setawnser4ID] = useState();
    const [awnser1, setawnser1] = useState();
    const [awnser2, setawnser2] = useState();
    const [awnser3, setawnser3] = useState();
    const [awnser4, setawnser4] = useState();
    const [checkawnser1, setcheckawnser1] = useState();
    const [checkawnser2, setcheckawnser2] = useState();
    const [checkawnser3, setcheckawnser3] = useState();
    const [checkawnser4, setcheckawnser4] = useState();

    useEffect(()=>{
        QuestionsClass.Getall(phone,code).then(res=>{
            if(res[0]){
                var filterdata = res[2].filter(item => item.id ==EditData.id)
                setawnser1ID(filterdata[0]?.answers[0].id);
                setawnser2ID(filterdata[0]?.answers[1].id);
                setawnser3ID(filterdata[0]?.answers[2].id);
                setawnser4ID(filterdata[0]?.answers[3].id);
               
                setawnser1(filterdata[0]?.answers[0].title)
                setawnser2(filterdata[0]?.answers[1].title)
                setawnser3(filterdata[0]?.answers[2].title)
                setawnser4(filterdata[0]?.answers[3].title)
                setcheckawnser1(filterdata[0]?.answers[0].status)
                setcheckawnser2(filterdata[0]?.answers[1].status)
                setcheckawnser3(filterdata[0]?.answers[2].status)
                setcheckawnser4(filterdata[0]?.answers[3].status)
            }
            else
            Toast("error",res[1]);
          
        })
    },[])
    // ======================================================================

    const ChangeCheckBox = (val) => {
        setcheckawnser1(false)
        setcheckawnser2(false)
        setcheckawnser3(false)
        setcheckawnser4(false)
        if (val == 1)
            setcheckawnser1(true)
        else if (val == 2)
            setcheckawnser2(true)
        else if (val == 3)
            setcheckawnser3(true)
        else
            setcheckawnser4(true)
    }

    const handleSubmitForm = (e) => {
        setLoading(true)
        e.preventDefault();
        if (title != '' && score != '' && awnser1 != '' && awnser2 != '' 
            && awnser3 != '' && awnser4 != '' && (checkawnser1==true || checkawnser2==true || checkawnser3==true || checkawnser4==true)  
         ) {


            const senddata = {
                id:EditData.id,
                title: title,
                score: score,
                answers:[
                    {
                        id:awnser1ID,
                        title:awnser1,
                        index:1,
                        status:checkawnser1
                    },
                    {
                        id:awnser2ID,
                        title:awnser2,
                        index:2,
                        status:checkawnser2
                    },
                    {
                        id:awnser3ID,
                        title:awnser3,
                        index:3,
                        status:checkawnser3
                    },
                    {
                        id:awnser4ID,
                        title:awnser4,
                        index:4,
                        status:checkawnser4
                    }
                ]

            }
            QuestionsClass.UpdateQuestion(senddata).then(res => {
                if (res[0]) {
                    Toast("success", res[1]);
                    close();
                    setLoading(false)
                }
            })
        }
        else {
            Toast("error", "اطلاعات را به درستی وارد کنید");
            setLoading(false)
        }
    };

    // ======================================================================

    // ======================================================================

    return (
        <div className="modal-wrapper">
            <div className="close-model-div" onClick={close}></div>
            <form onSubmit={handleSubmitForm} className={styles.modalAdd}>
                <div className={styles.informationContainer}>
                    <div className={styles.titleContainer}>
                        <img src="/imgs/Page/Personel/user.svg" />
                        <p>ویرایش سوال </p>
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">عنوان : </label>
                        <input placeholder="عنوان سوال را وارد کنید." type="text" value={title} onChange={(e) => settitle(e.target.value)} />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">امتیاز : </label>
                        <input placeholder="امتیاز سوال را وارد کنید." type="text" value={score} onChange={(e) => setscore(e.target.value)} />
                    </div>
                    <hr style={{width:"95%",margin:"10px"}}/>

                    <div className={styles.inputGroup}>
                        <div>
                            <label htmlFor="design_group">جواب اول : </label>
                        </div>
                        <div className={styles.inputgroupText}>
                            <input placeholder="جواب اول را وارد کنید." type="text" value={awnser1} onChange={(e) => setawnser1(e.target.value)} />
                            <input type="checkbox" style={{ width: "10%" }} checked={checkawnser1} onChange={() => ChangeCheckBox(1)} />
                        </div>

                    </div>
                   
                    <div className={styles.inputGroup}>
                        <div>
                            <label htmlFor="design_group">جواب دوم : </label>
                        </div>
                        <div className={styles.inputgroupText}>
                            <input placeholder="جواب دوم را وارد کنید." type="text" value={awnser2} onChange={(e) => setawnser2(e.target.value)} />
                            <input type="checkbox" style={{ width: "10%" }} checked={checkawnser2} onChange={() => ChangeCheckBox(2)} />
                        </div>

                    </div>
                    <div className={styles.inputGroup}>
                        <div>
                            <label htmlFor="design_group">جواب سوم : </label>
                        </div>
                        <div className={styles.inputgroupText}>
                            <input placeholder="جواب سوم را وارد کنید." type="text" value={awnser3} onChange={(e) => setawnser3(e.target.value)} />
                            <input type="checkbox" style={{ width: "10%" }} checked={checkawnser3} onChange={() => ChangeCheckBox(3)} />
                        </div>

                    </div>
                    <div className={styles.inputGroup}>
                        <div>
                            <label htmlFor="design_group">جواب چهارم : </label>
                        </div>
                        <div className={styles.inputgroupText}>
                            <input placeholder="جواب چهارم را وارد کنید." type="text" value={awnser4} onChange={(e) => setawnser4(e.target.value)} />
                            <input type="checkbox" style={{ width: "10%" }} checked={checkawnser4} onChange={() => ChangeCheckBox(4)} />
                        </div>

                    </div>
                </div>
                <div className={styles.btnContainer}>
                    <button className={styles.closeBtn} type="button" onClick={close}>
                        {language.cancle}
                    </button>
                    <button className={styles.confirmBtn} type="submit">
                        {language.save}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditQuestionModal;
