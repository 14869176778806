import React, { useEffect, useState } from "react";

import Authenticated from "./Authenticated";
import UnAuthenticated from "./UnAuthenticated";

import AuthContext from "../Context/AuthContext";
import LoadingContext from "../Context/LoadingContext";
import LanguageContext from "../Context/LanguageContext";
import set_site_language from "../Utils/LanguageFile";
import Loading from "../Components/Common/Loading";
const App = () => {
    const [loading, setLoading] = useState(false);

    // =======================================================================

    const [phone, setPhone] = useState(sessionStorage.getItem("Username"));
    const [code, setCode] = useState(sessionStorage.getItem("Password"));
    const [userphone, setuserphone] = useState(sessionStorage.getItem("userphone"));
    const [usercode, setusercode] = useState(sessionStorage.getItem("usercode"));

    // =======================================================================
    const [language, setLanguage] = useState({});

    // =======================================================================

    useEffect(() => {
        setLanguage(set_site_language("FA"));
    }, []);

    // =======================================================================

    // =======================================================================

    return (
        <AuthContext.Provider value={{ phone, setPhone, code, setCode,userphone,setuserphone,usercode,setusercode }}>
            <LanguageContext.Provider value={{ language, setLanguage }}>
                <LoadingContext.Provider value={{ loading, setLoading }}>
                    {phone && code ? <Authenticated /> : <UnAuthenticated />}
                    {loading ? <Loading /> : null}
                </LoadingContext.Provider>
            </LanguageContext.Provider>
        </AuthContext.Provider >
    );
};

export default App;
