import React, { useContext, useEffect, useState } from "react";

import styles from "../Styles/Personel/Personel.module.css";
import Header from "../Components/Common/Header";
import LanguageContext from "../Context/LanguageContext";
import AreYouSure from "../Components/Modal/Common/AreYouSure";
import Toast from "../Components/Common/Toast";

import Grid from "../Components/Common/Grid/Grid";
import AddNewCeremonyModal from "../Components/Ceremony/AddCeremonyModal";
import AddNewCeremonyModal1 from "../Components/Ceremony/AddCeremonyModal1";
import {ceremony} from '../Connection/Transaction'
import AuthContext from "../Context/AuthContext";
const Ceremony = (props) => {
    const { language } = useContext(LanguageContext);
    const { code, phone } = useContext(AuthContext);

    // ======================================================================

    const [personelsList, setPersonelsList] = useState([]);
    const [gridConfig, setGridConfig] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [newPersonelModal, setNewPersonelModal] = useState("");
    const [newPersonelModal1, setNewPersonelModal1] = useState("");
    const [selectedForEdit, setSelectedForEdit] = useState({});
    const [selectedForDelete, setSelectedForDelete] = useState({});

    // ======================================================================

    const handleDeleteItem = () => {
        setPersonelsList((prevState) => prevState.filter((item) => item.id != selectedForDelete.id));
        ceremony.Deletedata(selectedForDelete.id).then(res=>{
            if(res[0]){
                Toast("success",res[1]);
            }
            else
            Toast("error",res[1]);
        })
        setSelectedForDelete({});
    };

    // ======================================================================

    useEffect(() => {
        ceremony.Getall(phone,code).then(res=>{
            if(res[0]){
                setPersonelsList(res[2])
            }
            else
            Toast("error",res[1]);
          
        })
        // setPersonelsList([
        //     {
        //         id: 1,
        //         name: "test",
        //         startDate: "1402/11/5",
        //         startTime: "14:19",
        //         endDate: "1402/11/6",
        //         endTime: "14:19",
        //         tiket: "H10"
        //     }
           
        // ]);
        setGridConfig({
            column: [
                {
                    name: "id",
                    title: "ردیف",
                    type: "text",
                    data: "",
                    editable: true,
                    hidden: false,
                    search: true,
                    size: "100px",
                },
                {
                    name: "name",
                    title: "نام",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                },
                {
                    name: "startDate",
                    title: "تاریخ شروع",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "160px",
                },
                {
                    name: "startTime",
                    title: "زمان شروع",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                },
                {
                    name: "endDate",
                    title: "تاریخ پایان",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                },{
                    name: "endTime",
                    title: "ساعت پایان",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                },{
                    name: "lotteryDate",
                    title: "تاریخ قرعه کشی",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                },
                {
                    name: "lotteryTime",
                    title: "زمان قرعه کشی",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                },
                {
                    name: "tiket",
                    title: "کد",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                }
            ],
            setting: {
                edit: true,
                delete: true,
                search: false,
                add: false,
                select: false,
            },
        });
    }, []);
    const handleclose=()=>{
        ceremony.Getall(phone,code).then(res=>{
            if(res[0]){
                setPersonelsList(res[2])
            }
            else
            Toast("error",res[1]);
          
        })
        setNewPersonelModal(false)
    }
    const handleclose1=()=>{
        ceremony.Getall(phone,code).then(res=>{
            if(res[0]){
                setPersonelsList(res[2])
            }
            else
            Toast("error",res[1]);
          
        })
        setNewPersonelModal1(false)
    }
    // ======================================================================

    return (
        <div className={styles.wrapper}>
            <Header
                pageTitle={language.personel_list}
                newItemTitle={language.personel}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleNewBtn={() => setNewPersonelModal(true)}
                handleNewBtn1={() => setNewPersonelModal1(true)}
            />

            <div className={styles.contentContainer}>
                <Grid
                    data={personelsList}
                    config={gridConfig}
                    setSelectedForDelete={setSelectedForDelete}
                    itemInPerPage={15}
                    search={searchValue}
                    setSearch={setSearchValue}
                />
            </div>

            {newPersonelModal ? <AddNewCeremonyModal close={() => handleclose()} /> : null}
            {newPersonelModal1 ? <AddNewCeremonyModal1 close={() => handleclose1()} /> : null}
            {selectedForDelete.id ? (
                <AreYouSure
                    title={language.delete_modal_title.replace("{name}", language.personel)}
                    description={language.delete_modal_description.replace("{name}", `${selectedForDelete.name}`)}
                    confirm={handleDeleteItem}
                    close={() => setSelectedForDelete({})}
                />
            ) : null}
        </div>
    );
};

export default Ceremony;
