import React, { useState, useEffect } from "react";

import styles from "../../../Styles/Common/Grid.module.css";

import Row from "./Row";
import Selected from "./Selected.jsx";
import InsertJson from "./InsertJson.jsx";

import Toast from "../../../Components/Common/Toast.jsx";

import {userclass} from '../../..//Connection/Transaction'

import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import {ceremony} from '../../../Connection/Transaction.js'
import ReactPaginate from "react-paginate";

const Grid = ({ data: json, config: settingtemp, setSelectedForDelete, itemInPerPage, search, setSearch,TotalPage,phone,code }) => {
    const [rowJson, setRowJson] = useState([]);
    const [rowfilter, setRowfilter] = useState([]);
    const [jsonFilter, setJsonFilter] = useState([]);
    const [Initialize, setInitialize] = useState([]);

    const [setting, setsetting] = useState({});

    const [openInsert, setOpenInsert] = useState(false);
    const [checkUpdating, setCheckUpdating] = useState(true);

    //============================PageGrid===========================

    const [page, setPage] = useState(0);

    const employeesPerPage = itemInPerPage;
    const numberOfEmployeesVistited = page * employeesPerPage;

    //============================InsertJson=========================

    const NewRow = (settingtemp) => {
        var jsontemp = "{";
        for (var i = 0; i < settingtemp.column.length; i++) {
            if (i < settingtemp.column.length - 1) jsontemp += '"' + settingtemp.column[i].name + '":"",';
            else jsontemp += '"' + settingtemp.column[i].name + '":""}';
        }
        setInitialize([JSON.parse(jsontemp)]);
    };

    const SaveNewRow = () => {
        setRowJson([Initialize[0], ...rowJson]);
        setRowfilter([Initialize[0], ...rowfilter]);
        setOpenInsert(false);
        setCheckUpdating(true);
    };

    const DataChangeNewRow = (key, Value) => {
        var temp = Initialize[0];
        temp[key] = Value;
        setInitialize([temp]);
    };

    const submitChanged = (row) => {

        ceremony.UpdateData(row).then(res=>{
            if(res[0])
            {
                Toast("success",res[1]);
            }
            else
            {
                Toast("error",res[1]);
            }
        })
        const temp = [...rowJson];
        const wantedRowIndex = temp.findIndex((item) => item.id == row.id);
        temp[wantedRowIndex] = row;
        setRowJson(temp);
        setRowfilter(temp);
    };

    //=========================FilterJson===========================

    const NewRowFilter = (settingtemp) => {
        var jsonFilterArray = "{";
        for (var i = 0; i < settingtemp.column.length; i++) {
            if (i < settingtemp.column.length - 1) jsonFilterArray += '"' + settingtemp.column[i].name + '":"",';
            else jsonFilterArray += '"' + settingtemp.column[i].name + '":""}';
        }
        setJsonFilter([JSON.parse(jsonFilterArray)]);
    };

    const DataNewFilter = (key, value) => {
        var temp = jsonFilter[0];
        temp[key] = value;
        setJsonFilter([temp]);
        var rowfiltertemp = [...rowJson];
        Object.entries(temp).forEach(([key, value]) => {
            if (value != "") {
                rowfiltertemp = rowfiltertemp.filter((item) => String(item[key])?.startsWith(value));
            }
        });
        setRowfilter(rowfiltertemp);
    };

    const deleteFilters = (settingtemp) => {
        var temp = [...jsonFilter];
        settingtemp.column.forEach((element) => {
            temp[0][element.name] = "";
            temp[element.name] = "";
        });
        setJsonFilter(temp);
        setRowfilter(rowJson);
    };

    //===========================Json===============================

    useEffect(() => {
        setsetting(settingtemp);
        // NewRow(settingtemp);
        // NewRowFilter(settingtemp);
        
        setRowJson(json);
        setRowfilter(json);
    }, [json]);

    useEffect(() => {
        setsetting(settingtemp);
       if(TotalPage!=undefined)
        {
            userclass.Getall(phone,code,page+1).then(res=>{
                if(res[0]){
                    setRowJson(res[2]);
                    setRowfilter(res[2]);
                }
                else
                Toast("error",res[1]);
              
            })
        }
    }, [page]);

    //==========================ShowData============================

    var tempSearch = [];

    const Data = () => {
        if (search) {
            var rowsearch = [...rowfilter];
            rowsearch.forEach((item) => {
                var counter = -1;
                var insert = false;
                Object.entries(item).forEach((columnitem) => {
                    counter++;

                    
                    if (setting.column[counter] && setting.column[counter]["type"] == "combobox") {
                        var filterCombobox = setting.column[counter]["data"].filter((item) => item.name.startsWith(search));
                        filterCombobox.forEach((itemdata) => {
                            if (columnitem[1] == itemdata["id"]) insert = true;
                        });
                    } else {
                        if (String(columnitem[1]).startsWith(search)) insert = true;
                    }
                });
                if (insert) tempSearch.push(item);
            });

            return tempSearch.slice(numberOfEmployeesVistited, numberOfEmployeesVistited + employeesPerPage);
        } else {
            if(TotalPage!=undefined)
            {
                if(rowfilter==undefined || rowfilter==true || rowfilter==false)
                    return [];
                return rowJson;   
            }
            return rowfilter.slice(numberOfEmployeesVistited, numberOfEmployeesVistited + employeesPerPage);
        }
    };

    return (
        <div className={styles.grid}>
            {setting.setting?.search ? (
                <label className={styles.grideHeaderLabel}>
                    <div className={styles.grideHeaderIcon}>
                        <img src="imgs/search.png" />
                    </div>
                    <input type="search" value={search} onChange={(e) => setSearch(e.target.value)} className={styles.grideHeaderSearch} />
                </label>
            ) : null}
            <div className={styles.grideBorder}>
                <table className={styles.grideTable}>
                    <thead className={styles.tableHead}>
                        <tr className={styles.tableHeadTr}>
                            {setting.column?.map((columnS, index) => (
                                <>
                                    {columnS.hidden == true ? null : (
                                        <th
                                            className={`${styles.tableHeadTrThMap} ${columnS.type == "image" ? styles.tableHeadTrTh : ""}`}
                                            style={
                                                columnS.size
                                                    ? { minWidth: `${columnS.size}`, maxWidth: `${columnS.size}` }
                                                    : { minWidth: "150px", maxWidth: "150px" }
                                            }
                                            key={index}
                                            filterkey={index}
                                            casesensitive={true}
                                            showsearch={true}
                                        >
                                            {columnS.type == "image" ? (
                                                <div className={styles.tableHeadTrThInsideImg}>
                                                    <img src={columnS.title} />
                                                </div>
                                            ) : (
                                                <div className={styles.tableHeadTrThInside}>{columnS.title}</div>
                                            )}
                                        </th>
                                    )}
                                </>
                            ))}
                            <th className={styles.tableHeadTrTh}>
                                <div className={styles.tableHeadTrThInsideImg}>
                                    {/* {setting.setting?.edit ? <img src="/imgs/Common/Grid/edit.svg" /> : null} */}
                                </div>
                            </th>
                            <th className={styles.tableHeadTrTh}>
                                <div className={styles.tableHeadTrThInsideImg}>
                                    {/* {setting.setting?.delete ? <img src="/imgs/Common/Grid/trash.svg" /> : null} */}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    {setting.setting?.select == true ? (
                        <thead className={styles.tableHead}>
                            <tr>
                                <Selected
                                    Initialize={jsonFilter}
                                    setting={setting}
                                    DatePicker={DatePicker}
                                    persian={persian}
                                    persianFa={persian_fa}
                                    DataNewFilter={DataNewFilter}
                                    deleteFilters={deleteFilters}
                                />
                            </tr>
                        </thead>
                    ) : null}

                    <tbody className={styles.tableBody}>
                        {openInsert ? (
                            <tr>
                                <InsertJson
                                    Initialize={Initialize}
                                    setting={setting}
                                    DataChangeNewRow={DataChangeNewRow}
                                    setOpenInsert={setOpenInsert}
                                    SaveNewRow={SaveNewRow}
                                    DatePicker={DatePicker}
                                    persian={persian}
                                    persianFa={persian_fa}
                                    setCheckUpdating={setCheckUpdating}
                                />
                            </tr>
                        ) : null}

                        { Data().map((dataItem, index) => (
                            <tr className={styles.tableBodyTr} key={dataItem.id}>
                                <Row
                                    dataItem={dataItem}
                                    indexrow={index}
                                    setting={setting}
                                    deleteFunction={setSelectedForDelete}
                                    DatePicker={DatePicker}
                                    persian={persian}
                                    persianFa={persian_fa}
                                    submitChanged={submitChanged}
                                    checkUpdating={checkUpdating}
                                    setCheckUpdating={setCheckUpdating}
                                    DataNewFilter={DataNewFilter}
                                />
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className={styles.grideBottom}>
            {TotalPage ? <ReactPaginate
                    previousLabel={"قبلی"}
                    pageCount={TotalPage}
                    nextLabel={"بعدی"}
                    onPageChange={({ selected }) => setPage(selected)}
                    containerClassName={"navigationButtons"}
                    previousLinkClassName={"previousButton"}
                    nextLinkClassName={"nextButton"}
                    disabledClassName={"navigationDisabled"}
                    activeClassName={"navigationActive"}
                /> :
                <ReactPaginate
                    previousLabel={"قبلی"}
                    pageCount={search ? Math.ceil(tempSearch.length / employeesPerPage) : Math.ceil(rowfilter.length / employeesPerPage)}
                    nextLabel={"بعدی"}
                    onPageChange={({ selected }) => setPage(selected)}
                    containerClassName={"navigationButtons"}
                    previousLinkClassName={"previousButton"}
                    nextLinkClassName={"nextButton"}
                    disabledClassName={"navigationDisabled"}
                    activeClassName={"navigationActive"}
                />}
            </div>
        </div>
    );
};
export default Grid;
