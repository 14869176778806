import React from "react";

import styles from "../../Styles/Common/Headerprofile.module.css";

const Header = ({ pageTitle,phone,name,family }) => {
    return (
        <div className={styles.headerWrapper}>
            <p className={styles.title}>{pageTitle}</p>
            
            <div className={styles.ProfileHeader}>
             
               <div className={styles.ProfileContent}>نام: {name}</div>
               <div className={styles.ProfileContent}>نام خانوادگی: {family}</div>
               <div className={styles.ProfileContent}>شماره همراه:{phone}</div>

            </div>
        </div>
    );
};

export default Header;
