import React from "react";

import { Route, Routes, Navigate } from "react-router-dom";

import EnterPhone from "../Page/EnterPhone";
import EnterPhoneuser from "../Page/EnterPhoneuser";
import VerifyPhone from "../Page/VerifyPhone";
import UserInfo from "../Page/UserInfo";
import Adminlogin from "../Page/adminlogin";
import Useractivity from "../Page/useractivity";
import UserAnswerQuestions from "../Page/UserAnswer";
import RegisterAnswer from "../Page/RegisterAnswer";
import LoginAnswer from "../Page/LoginAnswer";

const UnAuthenticated = (props) => {
    return (
        <Routes>
            <Route path="/UserAnswer" element={<UserAnswerQuestions />} />
            <Route path="/enter-phone" element={<EnterPhone />} />
            <Route path="/userinfo" element={<UserInfo />} />
            <Route path="/admin" element={<Adminlogin />} />
            <Route path="/userlogin" element={<EnterPhoneuser />} />
            <Route path="/LoginAnswer" element={<LoginAnswer />} />
            <Route path="/VerifyPhone" element={<VerifyPhone />} />
            <Route path="/Useractivity" element={<Useractivity />} />
            <Route path="/Useractivity" element={<Useractivity />} />
            <Route path="/RegisterAnswer" element={<RegisterAnswer />} />
            <Route path="*" element={<Navigate to={"/LoginAnswer"} />} />
        </Routes>
    );
};

export default UnAuthenticated;
