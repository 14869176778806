import React, { useState, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";

import Toast from "../Components/Common/Toast";
import LoadingContext from "../Context/LoadingContext";
import styles from "../Styles/Authenticating/adminlogin.module.css";
import { adminlogin } from '../Connection/Transaction'
import AuthContext from "../Context/AuthContext";

const Adminlogin = () => {
    const { setLoading } = useContext(LoadingContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { setPhone, setCode } = useContext(AuthContext);

    const simpleValidator = useRef(
        new SimpleReactValidator({
            messages: {
                required: "پر کردن این فیلد الزامی می‌باشد!",
                min: "این فیلد حداقل باید شامل 11 کاراکتر باشد.",
                max: "این فیلد حداکثر باید شامل 11 کاراکتر باشد.",
                phone: "شماره تلفن وارد شده معتبر نمی‌باشد.",
            },
            element: (msg) => (
                <div className="validator-error-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width={20} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ff2c56">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
                        />
                    </svg>
                    <span className="validator-error-text">{msg}</span>
                </div>
            ),
        })
    );

    // =======================================================================

    const [, forceUpdate] = useState();
    const [username, setusername] = useState("");
    const [pass, setpass] = useState("");

    // =======================================================================

    const submitForm = (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            setLoading(true);

            adminlogin.Login(username, pass).then(res => {
                    // setCode(pass);
                    // setPhone(username);
                    // sessionStorage.setItem("Username", username);
                    // sessionStorage.setItem("Password", pass);

                if (res[0]) {
                    setCode(pass);
                    setPhone(username);
                    sessionStorage.setItem("Username", username);
                    sessionStorage.setItem("Password", pass);
                    Toast("success", " با موفقیت وارد شدید");
                }
                else {
                    Toast("error", res[1]);
                }
                setLoading(false);

            })
        } else {
            Toast("error", "لطفا فرم را به درستی کامل کن!");
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    // =======================================================================

    return (
        <div className={styles.MainContainer}>
           
            <div className={styles.AuthenticatingSection}>
                <div className={styles.AuthenticatingBody}>
                    <form className={styles.form} onSubmit={submitForm} autoComplete={null}>
                        <div className={styles.formSection}>
                            <label htmlFor="username" className={styles.formLabel}>
                                نام کاربری
                            </label>
                            <input
                                autoFocus
                                type="text"
                                name="username"
                                className={styles.formInput}
                                value={username}
                                placeholder="نام کاربری"
                                autoComplete={null}
                                onChange={(e) => {
                                    setusername(e.target.value);
                                    simpleValidator.current.showMessageFor("username");
                                }}
                            />
                            {simpleValidator.current.message("phoneNumber", username, "required")}
                        </div>
                        <div className={styles.formSection}>
                            <label htmlFor="code" className={styles.formLabel}>
                                رمز عبور
                            </label>
                            <input
                                type="password"
                                name="code"
                                className={styles.formInput}
                                value={pass}
                                autoComplete={null}
                                placeholder="رمز عبور"
                                onChange={(e) => {
                                    setpass(e.target.value);
                                    simpleValidator.current.showMessageFor("code");

                                }}
                            />
                            {simpleValidator.current.message("code", pass, "required")}
                        </div>

                        <button type="submit" className={styles.formBtn}>
                            ورود
                        </button>
                    </form>
                </div>
            </div>
           
        </div>
    );
};

export default Adminlogin;
