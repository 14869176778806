import axios from "axios";

import { TransAction_check } from "./Transaction";

axios.interceptors.response.use((res) => {
    if (res.headers["errorcode"] === "1056") {
        TransAction_check.Exit();
    }

    return Promise.resolve(res);
}, null);

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
};
