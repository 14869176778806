import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";
import LanguageContext from "../../Context/LanguageContext";
import LoadingContext from "../../Context/LoadingContext";

import styles from "../../Styles/Personel/AddNewPersonelModal.module.css";
import Toast from "../Common/Toast";
import { ceremony } from '../../Connection/Transaction'
const AddNewPersonelModal = ({ close }) => {
    const { language } = useContext(LanguageContext);
    const { setLoading } = useContext(LoadingContext);

    // ======================================================================

    const [name, setname] = useState("");
    const [code, setcode] = useState("");
    const [lastname, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [accessType, setAccessType] = useState({ value: 0, label: "انتخاب کنید." });
    const [isActive, setIsActive] = useState(true);
    const [datestart, setdatestart] = useState('');
    const [timestart, settimestart] = useState('');
    const [dateend, setdateend] = useState('');
    const [timeend, settimeend] = useState('');
    const [lotterydate, setlotterydate] = useState('');
    const [lotterytime, setlotterytime] = useState('');
    const [options, setOptions] = useState([]);
    const [radio, setradio] = useState(false);
    const [radio2, setradio2] = useState(false);
    const [optionType, setoptionType] = useState([{ value: 1, label: "پیامکی" }, { value: 2, label: "تستی" }]);
    const [selectOption, setselectOption] = useState(1);
    const [lotteryStep, setlotteryStep] = useState();
    // ======================================================================

    const handleSubmitForm = (e) => {
        setLoading(true)
        e.preventDefault();
        if (lotteryStep != '' && lotteryStep!=undefined) {


            const senddata = {
                name: name,
                startDate: datestart,
                startTime: timestart,
                endDate: dateend,
                endTime: timeend,
                lotteryTime: lotterytime,
                lotteryDate: lotterydate,
                step:lotteryStep,
                Type:selectOption,
                Everymath:radio2
            }
            ceremony.Cermonypostgroup(senddata, radio).then(res => {
                if (res[0]) {
                    console.log(res)
                    Toast("success", res[1]);
                    close();
                    setLoading(false)
                }
            })
        }
        else{
            Toast("error","بازه قرعه کشی نمیتواند خالی باشد");
            setLoading(false)
        }
    };

    // ======================================================================

    useEffect(() => {
        setOptions([
            { value: "1", label: "مدیر سیستم با دسترسی کامل" },
            { value: "2", label: "طراح" },
            { value: "3", label: "پشتیبان" },
        ]);
    }, []);

    const handleDate1Change = (event) => {
        let input = event.target.value;
        input = input.replace(/\D/g, '');
        if (input.length <= 4) {
            setdatestart(input);
        } else if (input.length <= 6) {
            setdatestart(`${input.slice(0, 4)}/${input.slice(4)}`);
        } else {
            setdatestart(`${input.slice(0, 4)}/${input.slice(4, 6)}/${input.slice(6, 8)}`);
        }
    };
    const handletime1Change = (event) => {
        let input = event.target.value;
        input = input.replace(/\D/g, '');
        if (input.length <= 2) {
            settimestart(input);
        } else if (input.length <= 6) {
            settimestart(`${input.slice(0, 2)}:${input.slice(2, 4)}`);
        }
    };
    const handleDate2Change = (event) => {
        let input = event.target.value;
        input = input.replace(/\D/g, '');
        if (input.length <= 4) {
            setdateend(input);
        } else if (input.length <= 6) {
            setdateend(`${input.slice(0, 4)}/${input.slice(4)}`);
        } else {
            setdateend(`${input.slice(0, 4)}/${input.slice(4, 6)}/${input.slice(6, 8)}`);
        }
    };
    const handletime2Change = (event) => {
        let input = event.target.value;
        input = input.replace(/\D/g, '');
        if (input.length <= 2) {
            settimeend(input);
        } else if (input.length <= 6) {
            settimeend(`${input.slice(0, 2)}:${input.slice(2, 4)}`);
        }
    };
    const handleDate3Change = (event) => {
        let input = event.target.value;
        input = input.replace(/\D/g, '');
        if (input.length <= 4) {
            setlotterydate(input);
        } else if (input.length <= 6) {
            setlotterydate(`${input.slice(0, 4)}/${input.slice(4)}`);
        } else {
            setlotterydate(`${input.slice(0, 4)}/${input.slice(4, 6)}/${input.slice(6, 8)}`);
        }
    };
    const handletime3Change = (event) => {
        let input = event.target.value;
        input = input.replace(/\D/g, '');
        if (input.length <= 2) {
            setlotterytime(input);
        } else if (input.length <= 6) {
            setlotterytime(`${input.slice(0, 2)}:${input.slice(2, 4)}`);
        }
    };
    // ======================================================================

    return (
        <div className="modal-wrapper">
            <div className="close-model-div" onClick={close}></div>
            <form onSubmit={handleSubmitForm} className={styles.modalAdd}>
                <div className={styles.informationContainer}>
                    <div className={styles.titleContainer}>
                        <img src="/imgs/Page/Personel/user.svg" />
                        <p>{language.newItemTitle.replace("{name}", language.personel)}</p>
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">{language.name} : </label>
                        <input placeholder="نام مراسم را وارد کنید." type="text" value={name} onChange={(e) => setname(e.target.value)} />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">تاریخ شروع : </label>
                        <input
                            type="text"
                            value={datestart}
                            onChange={handleDate1Change}
                            placeholder="yyyy/mm/dd"
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">ساعت شروع: </label>
                        <input
                            type="text"
                            value={timestart}
                            onChange={handletime1Change}
                            placeholder="HH:mm"
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">تاریخ پایان : </label>
                        <input
                            type="text"
                            value={dateend}
                            onChange={handleDate2Change}
                            placeholder="yyyy/mm/dd"
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">ساعت پایان: </label>
                        <input
                            type="text"
                            value={timeend}
                            onChange={handletime2Change}
                            placeholder="HH:mm"
                        />
                    </div>

                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">ساعت قرعه کشی: </label>
                        <input
                            type="text"
                            value={lotterytime}
                            onChange={handletime3Change}
                            placeholder="HH:mm"
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">بازه قرعه کشی: </label>
                        <input
                            type="number"
                            value={lotteryStep}
                            onChange={(e) => setlotteryStep(e.target.value)}
                            placeholder="1 تا 366"
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">نوع مسابقه: </label>
                        <select
                            className={styles.selectBoxmodal}
                            value={selectOption}
                            onChange={e => setselectOption(e.target.value)}
                        >
                            {
                                optionType.map(item => (
                                    <option value={item.value}>{item.label}</option>
                                ))
                            }

                        </select>
                    </div>
                    <div style={{ margin: "10px 0" }} >
                        <label htmlFor="design_group">  قرعه کشی برای هر مراسم: </label>
                        <input
                            type="checkbox"
                            checked={radio2 === true}
                            onChange={() =>setradio2(!radio2)}

                        />
                    </div>
                    <div style={{ margin: "10px 0" }} >
                        <label htmlFor="design_group">تاریخ قرعه کشی: </label>
                        <input
                            type="checkbox"
                            checked={radio === true}
                            onChange={() =>setradio(!radio)}

                        />
                    </div>

                    {radio  ? (<div className={styles.inputGroup}>
                        <label htmlFor="design_group">تاریخ قرعه کشی: </label>
                        <input
                            type="text"
                            value={lotterydate}
                            onChange={handleDate3Change}
                            placeholder="yyyy/mm/dd"
                        />
                    </div>) : null}

                </div>
                <div className={styles.btnContainer}>
                    <button className={styles.closeBtn} type="button" onClick={close}>
                        {language.cancle}
                    </button>
                    <button className={styles.confirmBtn} type="submit">
                        {language.save}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddNewPersonelModal;
