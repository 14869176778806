import React, { useContext, useEffect, useState } from "react";

import styles from "../Styles/Personel/Personel.module.css";
import Header from "../Components/Common/Header";
import LanguageContext from "../Context/LanguageContext";
import AreYouSure from "../Components/Modal/Common/AreYouSure";
import Toast from "../Components/Common/Toast";

import AddNewCeremonyModal from "../Components/Ceremony/AddCeremonyModal";
import AddQuestionModal from "../Components/Questions/AddQuestionModal";
import EditQuestionModal from "../Components/Questions/EditQuestionModal";
import {QuestionsClass,ceremony} from '../Connection/Transaction'
import AuthContext from "../Context/AuthContext";
import GridQuestions from "../Components/Common/Grid/GridQuestions";
const QuestionsPage = (props) => {
    const { language } = useContext(LanguageContext);
    const { code, phone } = useContext(AuthContext);

    // ======================================================================

    const [personelsList, setPersonelsList] = useState([]);
    const [questionList, setquestionList] = useState([]);
    const [gridConfig, setGridConfig] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [newPersonelModal, setNewPersonelModal] = useState("");
    const [newPersonelModal1, setNewPersonelModal1] = useState("");
    const [selectedForEdit, setSelectedForEdit] = useState({});
    const [selectedForDelete, setSelectedForDelete] = useState({});
    const [selectedForQuestion, setselectedForQuestion] = useState({});
    const [newQuestionModal, setnewQuestionModal] = useState("");

    // ======================================================================

    const handleDeleteItem = () => {
        setquestionList((prevState) => prevState.filter((item) => item.id != selectedForDelete.id));
        QuestionsClass.Deletedata(selectedForDelete.id).then(res=>{
            if(res[0]){
                Toast("success",res[1]);
            }
            else
            Toast("error",res[1]);
        })
        setSelectedForDelete({});
    };

    // ======================================================================

    useEffect(() => {
        QuestionsClass.Getall(phone,code).then(res=>{
            if(res[0]){
                setquestionList(res[2])
            }
            else
            Toast("error",res[1]);
        })
      
        // setPersonelsList([
        //     {
        //         id: 1,
        //         name: "test",
        //         startDate: "1402/11/5",
        //         startTime: "14:19",
        //         endDate: "1402/11/6",
        //         endTime: "14:19",
        //         tiket: "H10"
        //     }
           
        // ]);
        setGridConfig({
            column: [
                {
                    name: "id",
                    title: "ردیف",
                    type: "text",
                    data: "",
                    editable: true,
                    hidden: false,
                    search: true,
                    size: "100px",
                },
                {
                    name: "title",
                    title: "نام",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "750px",
                },
                {
                    name: "score",
                    title: "امتیاز",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "50px",
                }
            ],
            setting: {
                edit: false,
                delete: true,
                search: false,
                add: false,
                select: false,
                question:true
            },
        });
    }, []);
    const handleclose=()=>{
        QuestionsClass.Getall(phone,code).then(res=>{
            if(res[0]){
                setquestionList(res[2])
            }
            else
            Toast("error",res[1]);
          
        })
        setnewQuestionModal(false)
    }
    const handleclose1=()=>{
        setselectedForQuestion({})
        QuestionsClass.Getall(phone,code).then(res=>{
            if(res[0]){
                setquestionList(res[2])
            }
            else
            Toast("error",res[1]);
          
        })
    }
    const senddata = {
        id:1,
        title: '123',
        score: 123,
        awnsers:[
            {
                title:"amin1",
                status:true
            },
            {
                title:"amin2",
                status:false
            },
            {
                title:"amin3",
                status:false
            },
            {
                title:"amin4",
                status:false
            }
        ]

    }
    // ======================================================================

    return (
        <div className={styles.wrapper}>
            <Header
                pageTitle="لبست سوالات"
                newItemTitle="سوال"
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleNewBtn={() => setnewQuestionModal(true)}
            />

            <div className={styles.contentContainer}>
                <GridQuestions
                    data={questionList}
                    config={gridConfig}
                    setSelectedForDelete={setSelectedForDelete}
                    itemInPerPage={15}
                    search={searchValue}
                    setSearch={setSearchValue}
                    setSelectQuestion={setselectedForQuestion}
                />
            </div>
            {newQuestionModal ? <AddQuestionModal close={() => handleclose()} /> : null}
            {Object.keys(selectedForQuestion).length !== 0 ? <EditQuestionModal close={() => handleclose1() } EditData={selectedForQuestion} /> : null}
            {/* {Object.keys(selectedForQuestion).length !== 0 ? <EditQuestionModal close={() => handleclose1() } EditData={senddata} /> : null} */}
            {selectedForDelete.id ? (
                <AreYouSure
                    title="از حذف کردن اطمینان دارید؟"
                    description={language.delete_modal_description.replace("{name}", `${selectedForDelete.name}`)}
                    confirm={handleDeleteItem}
                    close={() => setSelectedForDelete({})}
                />
            ) : null}
        </div>
    );
};

export default QuestionsPage;
