import { createContext } from "react";

const AuthContext = createContext({
    phone: "",
    setPhone: () => { },
    code: "",
    setCode: () => { },
    userphone: "",
    setuserphone: () => { },
    usercode: "",
    setusercode: () => { }
});

export default AuthContext;