import React, { useState } from "react";

import styles from "../../../Styles/Common/InsertJson.module.css";

import Combobox from "../Grid/Combobox";

const InsertJson = ({ setting, DataChangeNewRow, setOpenInsert, Initialize, SaveNewRow, DatePicker, persian, persianFa, setCheckUpdating }) => {
    const [updating] = useState(true);

    return (
        <>
            <td className={styles.tableHeadTrTdCansel}>
                <div className={styles.tableHeadTrTdInsideOption}>
                    <img
                        src="imgs/cross.png"
                        onClick={() => {
                            setOpenInsert(false);
                            setCheckUpdating(true);
                        }}
                    />
                </div>
            </td>
            <td className={styles.tableHeadTrTdAccept}>
                <div className={styles.tableHeadTrTdInsideOption} onClick={() => SaveNewRow()}>
                    <img src="imgs/checkmark.png" />
                </div>
            </td>
            {setting.column?.map((Item) =>
                !Item.hidden ? (
                    <td
                        className={`${styles.tableHeadTrTdMap} ${Item.type == "image" ? styles.tableHeadTrTd : ""}`}
                        style={Item?.size ? { width: `${Item.size}` } : { width: "150px" }}
                    >
                        {Item.type == "image" ? (
                            <div className={styles.updatingImg}>
                                <label>
                                    <div className={styles.tableHeadTrTdInsideImgJson}>
                                        <img className={styles.updatingImg} src={Initialize[Item.name]} />
                                    </div>
                                    <input
                                        type="file"
                                        accept=".png,.svg,.jpg"
                                        onChange={(e) => DataChangeNewRow(Item.name, URL.createObjectURL(e.target.files[0]))}
                                        hidden
                                    />
                                </label>
                            </div>
                        ) : null}
                        {Item.type == "text" ? (
                            <div className={styles.tableHeadTrTdInside}>
                                <input
                                    type="text"
                                    className={styles.tableHeadTrTdInsideInput}
                                    value={Initialize[Item.name]}
                                    onChange={(e) => DataChangeNewRow(Item.name, e.target.value)}
                                />
                            </div>
                        ) : null}
                        {Item.type == "phone" ? (
                            <div className={styles.tableHeadTrTdInside}>
                                <input
                                    type="number"
                                    className={styles.tableHeadTrTdInsideInput}
                                    value={Initialize[Item.name]}
                                    onChange={(e) => DataChangeNewRow(Item.name, e.target.value.replace(/\D/g, ""))}
                                />
                            </div>
                        ) : null}
                        {Item.type == "check" ? (
                            <div className={styles.tableHeadTrTdInsideChekBox}>
                                <input
                                    type="checkbox"
                                    checked={Initialize[Item.name]}
                                    onChange={(e) => DataChangeNewRow(Item.name, e.target.value)}
                                />
                            </div>
                        ) : null}
                        {Item.type == "combobox" ?
                            <div className={styles.tableHeadTrTdInsideCombobox} >
                                <Combobox
                                    Open={updating}
                                    DataValue={Initialize[Item.name]}
                                    ListData={Item.data}
                                    ItemName={Item.name}
                                    DataChange={DataChangeNewRow}
                                />
                            </div >
                            : null}
                        {Item.type == "date" ? (
                            <div className={`${styles.Calender} ${updating ? styles.updatingCalender : ""}`}>
                                <DatePicker
                                    value={Initialize[Item.name]}
                                    onChange={(e) => DataChangeNewRow(Item.name, e.year + "\\" + e.month + "\\" + e.day)}
                                    calendar={persian}
                                    locale={persianFa}
                                />
                                {updating ? null : <div className={styles.inputDisabled}></div>}
                            </div>
                        ) : null}
                        {Item.type == "time" ? (
                            <input
                                className={styles.updatingTime}
                                id="appt-time"
                                type="time"
                                name="appt-time"
                                step="2"
                                value={Initialize[Item.name]}
                                onChange={(e) => DataChangeNewRow(Item.name, e.target.value)}
                            />
                        ) : null}
                    </td>
                ) : null
            )}
        </>
    );
};
export default InsertJson;
