import React, { useState, useRef, useContext } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";

import Toast from "../Components/Common/Toast";
import LoadingContext from "../Context/LoadingContext";
import AuthenticatingLayout from "../Container/Layout/AuthenticatingLayout";
import AuthContext from "../Context/AuthContext";
import styles from "../Styles/Authenticating/EnterPhone.module.css";

import stylescode from "../Styles/Authenticating/VerifyPhone.module.css";
import { Register } from '../Connection/Transaction'
const UserInfo = () => {

    const { setLoading } = useContext(LoadingContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [, forceUpdate] = useState();
    const [name, setname] = useState(location.state?.name ? location.state.name : "");
    const [family, setfamily] = useState(location.state?.family ? location.state.family : "");
    const [phoneNumber, setphoneNumber] = useState(location.state?.phoneNumber ? location.state.phoneNumber : "");
    const [code, setcode] = useState();
    const [tiket, settiket] = useState(location.state?.code ? location.state.code : "");
    const [registerok, setregisterok] = useState(false);
    //const { setPhone, setCode } = useContext(AuthContext);

    const simpleValidator = useRef(
        new SimpleReactValidator({
            messages: {
                required: "پر کردن این فیلد الزامی می‌باشد!",
                min: "این فیلد حداقل باید شامل 11 کاراکتر باشد.",
                max: "این فیلد حداکثر باید شامل 11 کاراکتر باشد.",
                phone: "شماره تلفن وارد شده معتبر نمی‌باشد.",
            },
            element: (msg) => (
                <div className="validator-error-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width={20} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ff2c56">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
                        />
                    </svg>
                    <span className="validator-error-text">{msg}</span>
                </div>
            ),
        })
    );

    // =======================================================================


    const validator = useRef(
        new SimpleReactValidator({
            element: () => { },
        })
    );

    // =============================================================

    const [one, setOne] = useState("");
    const [two, setTwo] = useState("");
    const [three, setTree] = useState("");
    const [four, setFour] = useState("");
    // =======================================================================

    const convert=(value)=> 
    {
        var persian = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
        var arabic = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
        var englis =['0','1','2','3','4','5','6','7','8','9'];
        
        for(let i=0;i<persian.length;i++)
        {
            value = value.replace(persian[i],englis[i]);
            value = value.replace(arabic[i],englis[i]);
        }
        return value;
    }
    
    const submitForm = (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            setLoading(true);
            const code = `${one}${two}${three}${four}`;
            var ncode=convert(code);
            const datasend = {
                "tiket": tiket,
                "code": ncode,
                "phone": phoneNumber,
                "name": name,
                "family": family
            }
            Register.RgisterInfo(datasend).then(res => {
                if (res[0]) {
                    setregisterok(true);
                    setLoading(false);
                    new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
                        navigate("/enter-phone")
                    });

                }
                else {
                    Toast("error", "خطایی رخ داده است");
                }
            }).catch(err => { console.log(err); setLoading(false) })
            setLoading(false);
            // new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
            //     setLoading(false);
            //     Toast("success", "ثبت نام شما انجام شد!");

            // });

            // GetData.LoginAndSingUp(phoneNumber).then(res => {
            //     if (res[0]) {
            //         Toast("success", "کد با موفقیت ارسال شد!");
            //         navigate("/verify-phone", { state: { phoneNumber } });
            //     } else {
            //         Toast("error", res[2])
            //     }
            //     setLoading(false)
            // }).catch(err => { console.log(err); setLoading(false) })
        } else {
            Toast("error", "لطفا فرم را به درستی کامل کن!");
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    const ChangeFocusedInput = (e, submit) => {
        if (e.target.value.length >= 1) {
            while ((e.target = e.target.nextElementSibling)) {
                if (e.target == null) break;
                if (e.target.tagName.toLowerCase() === "input") {
                    e.target.focus();
                    break;
                }
            }
        } else if (e.target.value.length === 0) {
            while ((e.target = e.target.previousElementSibling)) {
                if (e.target == null) break;
                if (e.target.tagName.toLowerCase() === "input") {
                    e.target.focus();
                    break;
                }
            }
        }

        if (submit && String(one) && String(two) && String(three)) {
            document.getElementById("submitBtn").click();
        }
    };

    // =======================================================================

    return (
        <AuthenticatingLayout>
            <div className={styles.AuthenticatingHeader}>
            </div>
            <div className={styles.AuthenticatingSection}>
                <div className={styles.AuthenticatingBody}>
                    {!registerok ? (
                        <form className={styles.form} onSubmit={submitForm} autoComplete={null}>

                            {location.state?.name == null ?
                                (
                                    <div className={styles.formSection}>
                                        <label htmlFor="name" className={styles.formLabel}>
                                            نام خود را وارد کنید
                                        </label>
                                        <input
                                            autoFocus
                                            type="text"
                                            name="name"
                                            className={styles.formInput}
                                            value={name}
                                            autoComplete={null}
                                            placeholder="نام"
                                            onChange={(e) => {
                                                setname(e.target.value);
                                                simpleValidator.current.showMessageFor("name");
                                            }}
                                        />
                                        {simpleValidator.current.message("name", name, "required")}
                                    </div>
                                )
                                : ""}
                            {
                                location.state?.name == null ? (
                                    <div className={styles.formSection}>
                                        <label htmlFor="family" className={styles.formLabel}>
                                            نام خانوادگی خود را وارد کنید
                                        </label>
                                        <input
                                            autoFocus
                                            type="text"
                                            name="family"
                                            className={styles.formInput}
                                            value={family}
                                            autoComplete={null}
                                            placeholder="نام خانوادگی"
                                            onChange={(e) => {
                                                setfamily(e.target.value);
                                                simpleValidator.current.showMessageFor("family");

                                            }}
                                        />
                                        {simpleValidator.current.message("family", family, "required")}
                                    </div>
                                ) : ""
                            }
                            <div className={styles.formSection}>
                                <label htmlFor="phoneNumber" className={stylescode.formLabelCode}>
                                    کد دریافتی
                                </label>
                                <div className={stylescode.sectionInputCode}>
                                    <input
                                        autoFocus
                                        onFocus={(e) => e.target.select()}
                                        maxLength={1}
                                        autoComplete={null}
                                        className={stylescode.fromInputCode}
                                        type="text"
                                        value={one}
                                        name={"one"}
                                        onChange={(e) => (e.target.value.length < 2 ? setOne(e.target.value) : null)}
                                        onKeyUp={ChangeFocusedInput}
                                    />
                                    <input
                                        onFocus={(e) => e.target.select()}
                                        maxLength={1}
                                        autoComplete={null}
                                        className={stylescode.fromInputCode}
                                        type="text"
                                        name={"two"}
                                        value={two}
                                        onChange={(e) => (e.target.value.length < 2 ? setTwo(e.target.value) : null)}
                                        onKeyUp={ChangeFocusedInput}
                                    />
                                    <input
                                        onFocus={(e) => e.target.select()}
                                        maxLength={1}
                                        autoComplete={null}
                                        className={stylescode.fromInputCode}
                                        type="text"
                                        name={"three"}
                                        value={three}
                                        onChange={(e) => (e.target.value.length < 2 ? setTree(e.target.value) : null)}
                                        onKeyUp={ChangeFocusedInput}
                                    />
                                    <input
                                        onFocus={(e) => e.target.select()}
                                        maxLength={1}
                                        autoComplete={null}
                                        className={stylescode.fromInputCode}
                                        type="text"
                                        name={"four"}
                                        value={four}
                                        onChange={(e) => (e.target.value.length < 2 ? setFour(e.target.value) : null)}
                                        onKeyUp={(e) => ChangeFocusedInput(e, true)}
                                    />
                                    {validator.current.message("one", one, "required")}
                                    {validator.current.message("two", two, "required")}
                                    {validator.current.message("three", three, "required")}
                                    {validator.current.message("four", four, "required")}
                                </div>
                            </div>


                            <button id="submitBtn" type="submit" className={styles.formBtn}>
                                تائید کد
                            </button>
                            <div className={stylescode.sectionFormLink}>
                                <Link to="/enter-phone" state={{ phoneNumber: location.state?.phoneNumber }} className={styles.formLink}>
                                    ویرایش شماره
                                </Link>
                            </div>
                        </form>

                    ) : (

                        <div>
                            <span className={styles.spansuccess}>ثبت نام شما با موفقیت انجام شد</span>
                        </div>
                    )
                    }

                </div>
            </div>

            <div className={styles.AuthenticatingBottom}>
                <span className={styles.spantitle}>امام علی علیه السلام: </span>
                <span className={styles.spantitle}>مال اندوزان، با آن كه زنده‌اند، مُرده‌اند و اهل علم، تا دنيا هست زنده‌اند. پيكرهايشان از ميان مى‌رود، امّا يادشان در دلها هست.</span>
                <span className={styles.spanfooter}>نهج البلاغة : الحكمة ۱۴۷</span>
            </div>
        </AuthenticatingLayout>
    );
};

export default UserInfo;
