const EN = {};

// const FA = {
//     name: "نام",
//     family: "نام خانوادگی",
//     new: "جدید",
//     cancle: "انصراف",
//     confirm: "تایید",
//     delete: "حذف",
//     edit: "ویرایش",
//     save: "ذخیره",
//     price: "قیمت",
//     file: "فایل",
//     poster: "پوستر",
//     tag: "برچسب",
//     phone_invalid: "شماره تلفن وارد شده معتبر نمی‌باشد!",
//     there_is_this_tag: "این برچسب قبلا ثبت شده است.",
//     publish_status: "وضعیت انتشار",
//     published: "انتشار شده",
//     no_published: "انتشار نشده",
//     logout: "خروج از برنامه",
//     search: "جستجو ...",
//     seen: "خوانده شده",
//     no_seen: "خوانده نشده",
//     phoneNumber: "شماره تماس",
//     newItemTitle: "ثبت {name} جدید",
//     editItemTitle: "ویرایش {name}",
//     last_login: "آخرین بازدید",
//     signup_date: "تاریخ ثبت نام",
//     // =============================================
//     group: "گروه‌‌بندی",
//     group_name: "نام گروه",
//     group_icon: "آیکون گروه ( SVG )",
//     // =============================================
//     thing: "شئ",
//     things_list: "لیست اشیاء",
//     thing_group: "گروه شئ",
//     thing_name: "نام شئ",
//     show_people: "برای عموم نمایش داده شود",
//     // =============================================
//     design: "طرح",
//     designs_list: "لیست طرح ها",
//     design_group: "گروه طرح",
//     design_name: "نام طرح",
//     doing_time: "زمان تقریبی اجرا",
//     design_time: "زمان تقریبی طراحی",
//     warrenty: "مدت زمان گارانتی",
//     design_price: "قیمت تقریبی ( تومان )",
//     showing_name: "نام نمایشی",
//     select_design_group_placeholder: "گروه طرح را انتخاب کنید.",
//     // =============================================
//     delete_modal_title: "از حذف کردن این {name} اطمینان دارید؟",
//     delete_modal_description: "{name} حذف شود؟",
//     deleted_successfuly: "{name} با موفقیت حذف شد!",
//     add_successfuly: "{name} با موفقیت اضافه شد!",
//     edit_successfuly: "{name} با موفقیت ویرایش شد!",
//     // =============================================
//     personel_list: "لیست پرسنل",
//     personel: "پرسنل",
//     personel_access: "نوع دسترسی کاربر",
//     isActive: "فعال باشد",
//     firstname_error_personel: "لطفا نام پرسنل را وارد کنید.",
//     lastname_error_personel: "لطفا نام خانوادگی پرسنل را وارد کنید.",
//     phone_error_personel: "لطفا شماره تلفن پرسنل را وارد کنید.",
//     access_error_personel: "لطفا دسترسی پرسنل را وارد کنید.",
//     // =============================================
//     user_list: "لیست کاربران",
//     user: "کاربر",
// };

const FA = {
    name: "نام",
    family: "نام خانوادگی",
    new: "جدید",
    cancle: "انصراف",
    confirm: "تایید",
    delete: "حذف",
    edit: "ویرایش",
    save: "ذخیره",
    price: "قیمت",
    file: "فایل",
    poster: "پوستر",
    tag: "برچسب",
    phone_invalid: "شماره تلفن وارد شده معتبر نمی‌باشد!",
    there_is_this_tag: "این برچسب قبلا ثبت شده است.",
    publish_status: "وضعیت انتشار",
    published: "انتشار شده",
    no_published: "انتشار نشده",
    logout: "خروج از برنامه",
    search: "جستجو ...",
    seen: "خوانده شده",
    no_seen: "خوانده نشده",
    phoneNumber: "شماره تماس",
    newItemTitle: "ثبت {name} جدید",
    editItemTitle: "ویرایش {name}",
    last_login: "آخرین بازدید",
    signup_date: "تاریخ ثبت نام",
    // =============================================
    group: "گروه‌‌بندی",
    group_name: "نام گروه",
    group_icon: "آیکون گروه ( SVG )",
    // =============================================
    thing: "شئ",
    things_list: "لیست اشیاء",
    thing_group: "گروه شئ",
    thing_name: "نام شئ",
    show_people: "برای عموم نمایش داده شود",
    // =============================================
    design: "طرح",
    designs_list: "لیست طرح ها",
    design_group: "گروه طرح",
    design_name: "نام طرح",
    doing_time: "زمان تقریبی اجرا",
    design_time: "زمان تقریبی طراحی",
    warrenty: "مدت زمان گارانتی",
    design_price: "قیمت تقریبی ( تومان )",
    showing_name: "نام نمایشی",
    select_design_group_placeholder: "گروه طرح را انتخاب کنید.",
    // =============================================
    delete_modal_title: "از حذف کردن این {name} اطمینان دارید؟",
    delete_modal_description: "{name} حذف شود؟",
    deleted_successfuly: "{name} با موفقیت حذف شد!",
    add_successfuly: "{name} با موفقیت اضافه شد!",
    edit_successfuly: "{name} با موفقیت ویرایش شد!",
    // =============================================
    personel_list: "لیست مراسمات",
    personel: "مراسم",
    personel_access: "نوع دسترسی کاربر",
    isActive: "فعال باشد",
    firstname_error_personel: "لطفا نام پرسنل را وارد کنید.",
    lastname_error_personel: "لطفا نام خانوادگی پرسنل را وارد کنید.",
    phone_error_personel: "لطفا شماره تلفن پرسنل را وارد کنید.",
    access_error_personel: "لطفا دسترسی پرسنل را وارد کنید.",
    // =============================================
    user_list: "لیست کاربران",
    user: "کاربر",
};

const set_site_language = (language) => {
    switch (language.toUpperCase()) {
        case "EN":
            return EN;
        case "FA":
            return FA;
        default:
            return FA;
    }
};

export default set_site_language;
