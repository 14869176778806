import React from "react";

import styles from "../../Styles/Container/AuthenticatingLayout.module.css";

const AuthenticatingLayout = ({ children }) => {
    return (
        // <div className={styles.AuthenticatingLayout}>
        //     <div className={styles.backAuthenticating}>
        //         <img src="/imgs/Authenticating/AuthenticatingBack.jpg" alt="Authenticating Image" />
        //     </div>
        //     <div className={styles.AuthenticatingCom}>
        //         {children}
        //     </div>
        // </div>
        <div className={styles.AuthenticatingLayout}>
            <div className={styles.LeftContainer}>
            </div>
            <div className={styles.AuthenticatingCom}>

                {children}
            </div>
            <div className={styles.RightContainer}>
                <div className={styles.socialmedia}>
                    <a href="https://web.eitaa.com/#@alisaboohi_com"> <img src="/imgs/imgs/eata.svg" className={styles.imagesSize} /></a>
                    <a href="https://t.me/alisaboohi_com"><img src="/imgs/imgs/telegram.svg" className={styles.imagesSize} /></a>
                    <a href="https://www.instagram.com/alisaboohitasooji/"><img src="/imgs/imgs/insta.svg" className={styles.imagesSize} /></a>
                    <a href="https://web.bale.ai/chat/search?uid=6050745605"><img src="/imgs/imgs/youtube.svg" className={styles.imagesSize} /></a>
                </div>
            </div>
        </div>
    )
}

export default AuthenticatingLayout;
