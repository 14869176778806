import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";

import styles from "../../Styles/Common/Menu.module.css";
import LanguageContext from "../../Context/LanguageContext";
import { MenuJson } from "../../Utils/JsonFile";
// import { GetData } from "../../Connections/Transaction";

const Menu = ({ page, setPage, isOpen, setIsOpen }) => {
    const { language } = useContext(LanguageContext);
    const { setPhone, setCode } = useContext(AuthContext);

    // ===================================================================

    const [menu, setMenu] = useState({});
    const [showChildren, setShowChildren] = useState(false);

    // ===================================================================

    const handleLogout = () => {
        setCode("");
        setPhone("");
        sessionStorage.clear();
    };

    // ===================================================================

    useEffect(() => {
        setMenu(MenuJson());
    }, []);

    // ===================================================================

    return (
        <>
            <div className={`${styles.container} ${isOpen ? null : styles.min}`}>
                <div htmlFor="Menu" className={styles.containerLogo}>
                    <img
                        className={`${styles.containerLogoImg} myLogo`}
                        src="imgs/Menu/Logo.png"
                        onClick={() => {
                            setIsOpen((prevState) => !prevState);
                            setShowChildren(false);
                        }}
                    />
                </div>

                <div className={styles.containerInside}>
                    {menu.child?.map((item) => (
                        <div className={styles.containerInsideItem} key={item.id}>
                            <div className={styles.containerTitle}>
                                <div className={styles.containerTitleDiv}>
                                    <p className={styles.containerTitleText}>{item.name}</p>
                                </div>
                                <div className={styles.containerTitleLine}></div>
                            </div>
                            <ul className={styles.containerUl}>
                                {item.child?.map((item) => (
                                    <li
                                        className={`${styles.containerUlLi} ${item.tabIndex == page ? styles.active : ""}`}
                                        key={item.id}
                                        onClick={() => setPage(item.tabIndex)}
                                    >
                                        <div className={styles.containerUlLiInside} onClick={() => setShowChildren(!showChildren)}>
                                            <div className={styles.containerUlLiInsideIcon} dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                                            <p className={styles.containerUlLiInsideText}>{item.name}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                <li className={`${styles.containerUlLi} ${styles.logout}`} onClick={handleLogout}>
                    <div className={styles.containerUlLiInside} onClick={() => setShowChildren(!showChildren)}>
                        <div className={styles.containerUlLiInsideIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                <path
                                    d="M9.40002 8.06023C9.71002 4.46023 11.56 2.99023 15.61 2.99023H15.74C20.21 2.99023 22 4.78023 22 9.25023V15.7702C22 20.2402 20.21 22.0302 15.74 22.0302H15.61C11.59 22.0302 9.74002 20.5802 9.41002 17.0402"
                                    stroke="#FF2F54"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path d="M15.5 12.5H4.12" stroke="#FF2F54" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path
                                    d="M6.35 9.1499L3 12.4999L6.35 15.8499"
                                    stroke="#FF2F54"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                        <p className={styles.containerUlLiInsideText}>{language.logout}</p>
                    </div>
                </li>
               
            </div>
        </>
    );
};

export default Menu;
