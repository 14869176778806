import React, { useContext, useEffect, useState } from "react";

import styles from "../Styles/Personel/Personel.module.css";
import Header from "../Components/Common/Header";
import LanguageContext from "../Context/LanguageContext";
import AreYouSure from "../Components/Modal/Common/AreYouSure";
import Toast from "../Components/Common/Toast";

import Grid from "../Components/Common/Grid/Grid";
import AddNewcerQuestionModal from "../Components/CermonyQuestion/AddNewCerQuestion"
import { giftclass,ceremony,CermonyQuestionlass } from '../Connection/Transaction'
import AuthContext from "../Context/AuthContext";
const CerQuestions = (props) => {
    const { language } = useContext(LanguageContext);
    const { code, phone } = useContext(AuthContext);

    // ======================================================================

    const [personelsList, setPersonelsList] = useState([]);
    const [questionsList, setquestionsList] = useState([]);
    const [gridConfig, setGridConfig] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [newPersonelModal, setNewPersonelModal] = useState("");
    const [selectedForDelete, setSelectedForDelete] = useState({});
    const [selectbox, setselectbox] = useState({});
    const [selectedcermony, setselectedcermony] = useState();

    // ======================================================================

    const handleDeleteItem = () => {
        setquestionsList((prevState) => prevState.filter((item) => item.id != selectedForDelete.id));
        CermonyQuestionlass.Deletedata(selectedcermony,selectedForDelete.id).then(res => {
            if (res[0]) {
                Toast("success", res[1]);
            }
            else
                Toast("error", res[1]);
        })
        setSelectedForDelete({});
    };

    // ======================================================================

    useEffect(() => {
        ceremony.GetallByType(phone, code,2).then(res => {
            if (res[0]) {
                if (res[2].length > 0) {
                    setselectbox(res[2])
                    setselectedcermony(res[2][0].id);
                    CermonyQuestionlass.Getall(phone, code,res[2][0].id).then(res=>{
                        if (res[0]) {
                            setquestionsList(res[2])
                        }
                        else
                            Toast("error", res[1]);

                    })
                }
            }
            else
                Toast("error", res[1]);

        })


        // setquestionsList([
        //     {
        //         id: 1,
        //         title: "آیا در زمینه استفاده از این برنامه تخصصی دارید؟ دارم دارم خیلیییی دارم ",
        //         score: 10
        //     }

        // ]);
        setGridConfig({
            column: [
                {
                    name: "id",
                    title: "ردیف",
                    type: "text",
                    data: "",
                    editable: true,
                    hidden: false,
                    search: true,
                    size: "50px",
                },
                {
                    name: "title",
                    title: "عنوان سوال",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "1000px",
                },
                {
                    name: "score",
                    title: "امتیاز",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "50px",
                }

            ],
            setting: {
                edit: false,
                delete: true,
                search: false,
                add: false,
                select: false,
            },
        });
    }, []);
    const handleclose = () => {
        if (selectbox.length > 0) {
            CermonyQuestionlass.Getall(phone, code,selectedcermony).then(res=>{
                if (res[0]) {
                    setquestionsList(res[2])
                }
                else
                    Toast("error", res[1]);

            })
        }
        setNewPersonelModal(false)
    }
    const ChangeSelectbox = (val) => {
        setselectedcermony(val);
        CermonyQuestionlass.Getall(phone, code,val).then(res=>{
            if (res[0]) {
                setquestionsList(res[2])
            }
            else
                Toast("error", res[1]);

        })
    }

    // ======================================================================

    return (
        <div className={styles.wrapper}>
            <Header
                pageTitle="لیست سوالات مراسم"
                newItemTitle="سوال مراسم"
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleNewBtn={() => setNewPersonelModal(true)}
            />

            <select className={styles.selectBox} onChange={(e) => ChangeSelectbox(e.target.value)}>
                {selectbox.length > 0 ? selectbox.map(x => (<option value={x.id}>{x.name}</option>))
                    : ""
                }
            </select>

            <div className={styles.contentContainer}>
                <Grid
                    data={questionsList}
                    config={gridConfig}
                    setSelectedForDelete={setSelectedForDelete}
                    itemInPerPage={15}
                    search={searchValue}
                    setSearch={setSearchValue}
                />
            </div>

            {newPersonelModal ? <AddNewcerQuestionModal close={() => handleclose()} cermonyId={selectedcermony} usedQuestions={questionsList} /> : null}
            {selectedForDelete.id ? (
                <AreYouSure
                    title={language.delete_modal_title.replace("{name}", "سوال")}
                    description={"حذف شود؟"}
                    confirm={handleDeleteItem}
                    close={() => setSelectedForDelete({})}
                />
            ) : null}
        </div>
    );
};

export default CerQuestions;
