import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../Styles/Useractivity/Useractivity.module.css";
import Header from "../Components/Common/HeaderProfile";
import LanguageContext from "../Context/LanguageContext";
import Grid from "../Components/Common/Grid/Grid";
import AuthContext from "../Context/AuthContext";
const Useractivity = (props) => {
    const location = useLocation();
    // ======================================================================

    const [personelsList, setPersonelsList] = useState([]);
    const [GiftList, setGiftList] = useState([]);
    const [gridConfig, setGridConfig] = useState([]);
    const [GiftListgridConfig, setGiftListgridConfig] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedForDelete, setSelectedForDelete] = useState({});

    // ======================================================================


    // ======================================================================

    useEffect(() => {

        console.log(location.state)
        setPersonelsList(location?.state?.listCermonies)
        setGiftList(location?.state?.listgift)

        setGiftListgridConfig(
            {
                column: [
                    {
                        name: "name",
                        title: "جایزه",
                        type: "text",
                        data: "",
                        editable: true,
                        hidden: false,
                        search: true,
                        size: "150px",
                    },
                    {
                        name: "date",
                        title: "تاریخ",
                        type: "text",
                        data: "",
                        editable: false,
                        hidden: false,
                        search: false,
                        size: "100px",
                    },
                    {
                        name: "cermonyName",
                        title: "مراسم",
                        type: "text",
                        data: "",
                        editable: false,
                        hidden: false,
                        search: false,
                        size: "100px",
                    }
                    ,
                    {
                        name: "index",
                        title: "رتبه",
                        type: "text",
                        data: "",
                        editable: false,
                        hidden: false,
                        search: false,
                        size: "100px",
                    }
                ],
                setting: {
                    edit: false,
                    delete: false,
                    search: false,
                    add: false,
                    select: false,
                },
            }
        )
        setGridConfig({
            column: [
                {
                    name: "name",
                    title: "مراسم",
                    type: "text",
                    data: "",
                    editable: true,
                    hidden: false,
                    search: true,
                    size: "200px",
                },
                {
                    name: "startDate",
                    title: "تاریخ شروع",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "100px",
                },
                {
                    name: "endDate",
                    title: "تاریخ پایان",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "100px",
                }
            ],
            setting: {
                edit: false,
                delete: false,
                search: false,
                add: false,
                select: false,
            },
        });
    }, []);

    // ======================================================================

    return (
        <div className={styles.wrapper}>
            <Header
                pageTitle="پروفایل کاربری"
                phone={location.state.phone}
                name={location.state.name}
                family={location.state.family}
            />

            <div className={styles.contentContainer}>
                <div className={styles.flexitem}>
                    <Grid
                        data={personelsList}
                        config={gridConfig}
                        setSelectedForDelete={setSelectedForDelete}
                        itemInPerPage={15}
                        search={searchValue}
                        setSearch={setSearchValue}
                    />
                </div>
                <div className={styles.flexitem}>
                    <Grid
                        data={GiftList}
                        config={GiftListgridConfig}
                        setSelectedForDelete={setSelectedForDelete}
                        itemInPerPage={15}
                        search={searchValue}
                        setSearch={setSearchValue}
                    />
                </div>
            </div>


        </div>
    );
};

export default Useractivity;
