import React, { useState } from "react";

import Menu from "../../Components/Common/Menu";
import MenuContext from "../../Context/MenuContext";
import MobileNavbar from "../../Components/Common/MobileNavbar";

import styles from "../../Styles/Container/MainLayout.module.css";

const MainLayout = ({ page, setPage, children }) => {
    const [isOpen, setIsOpen] = useState(window.innerWidth > 992);
    const [pageTitle, setPageTitle] = useState("");

    // ================================================================

    return (
        <MenuContext.Provider value={{ pageTitle, setPageTitle }}>
            <div className={styles.container}>
                <Menu page={page} setPage={setPage} isOpen={isOpen} setIsOpen={setIsOpen} />
                <div
                    className={`${styles.contentWrapper} ${isOpen ? styles.menuIsOpen : ""}`}
                    onClick={() => (isOpen ? (window.innerWidth < 992 ? setIsOpen(false) : null) : null)}
                >
                    <MobileNavbar isOpen={isOpen} setIsOpen={setIsOpen} pageTitle={pageTitle} />
                    <section className={styles.contentWrapper}>{children}</section>
                </div>
            </div>
        </MenuContext.Provider>
    );
};

export default MainLayout;
