import SimpleReactValidator from "simple-react-validator";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useContext } from "react";

import Toast from "../Components/Common/Toast";

import AuthContext from "../Context/AuthContext";
import LoadingContext from "../Context/LoadingContext";
import AuthenticatingLayout from "../Container/Layout/AuthenticatingLayout";
import { UserLogin } from '../Connection/Transaction'

import styles from "../Styles/Authenticating/VerifyPhone.module.css";

const VerifyPhone = () => {
    const { setuserphone, setusercode } = useContext(AuthContext);
    const { setLoading } = useContext(LoadingContext);
    const navigate = useNavigate();
    const location = useLocation();
    const validator = useRef(
        new SimpleReactValidator({
            element: () => { },
        })
    );

    // =============================================================

    const [one, setOne] = useState("");
    const [two, setTwo] = useState("");
    const [three, setTree] = useState("");
    const [four, setFour] = useState("");

    // =============================================================

    const ChangeFocusedInput = (e, submit) => {
        if (e.target.value.length >= 1) {
            while ((e.target = e.target.nextElementSibling)) {
                if (e.target == null) break;
                if (e.target.tagName.toLowerCase() === "input") {
                    e.target.focus();
                    break;
                }
            }
        } else if (e.target.value.length === 0) {
            while ((e.target = e.target.previousElementSibling)) {
                if (e.target == null) break;
                if (e.target.tagName.toLowerCase() === "input") {
                    e.target.focus();
                    break;
                }
            }
        }

        if (submit && String(one) && String(two) && String(three)) {
            document.getElementById("submitBtn").click();
        }
    };

    const convert=(value)=> 
    {
        var persian = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
        var arabic = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
        var englis =['0','1','2','3','4','5','6','7','8','9'];
        
        for(let i=0;i<persian.length;i++)
        {
            value = value.replace(persian[i],englis[i]);
            value = value.replace(arabic[i],englis[i]);
        }
        return value;
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            setLoading(true);
            const code = `${one}${two}${three}${four}`;
            var ncode=convert(code);
            UserLogin.VerifyUser(location.state?.phoneNumber, ncode).then(res => {
                if (res[0]) {

                    setusercode(ncode);
                    setuserphone(location.state?.phoneNumber);

                    sessionStorage.setItem("usercode", ncode);
                    sessionStorage.setItem("userphone", location.state?.phoneNumber);
                    navigate('/Useractivity', { state: res[2] });
                    Toast("success", "با موفقیت وارد شدید!");

                }
                else
                    Toast("success", res[1]);

                setLoading(false);

            });

            // GetData.ConfirmPhone(location.state?.phoneNumber, code).then(res => {
            //     if (res[0]) {
            //         setCode(code);
            //         setUserId(res[2][0].card.id)
            //         setPhone(location.state?.phoneNumber);
            //         Toast("success", "با موفقیت وارد شدید!");
            //     } else {
            //         Toast("error", res[2]);
            //     }
            //     setLoading(false)
            // }).catch(err => { console.log(err); setLoading(false) })
        } else {
            Toast("error", "لطفا کد را به درستی وارد کنید!");
            setLoading(false);
        }

    };

    // =============================================================

    useEffect(() => {
        if (!!!location.state?.phoneNumber) {
            navigate("/enter-phone");
        }
    }, []);

    // =============================================================

    return (
        <AuthenticatingLayout>
            <div className={styles.AuthenticatingSection}>
                <div className={styles.AuthenticatingHeader}>
                    <h2 className={styles.AuthenticatingTitle}>ورود</h2>
                    <p className={styles.AuthenticatingP}>کد چهار رقمی که به شماره {location.state?.phoneNumber} ارسال شده است را وارد کن</p>
                </div>
                <div className={styles.AuthenticatingBody}>
                    <form onSubmit={handleSubmitForm} className={styles.form} autoComplete={null}>
                        <div className={styles.formSection}>
                            <label htmlFor="phoneNumber" className={styles.formLabelCode}>
                                کد دریافتی
                            </label>
                            <div className={styles.sectionInputCode}>
                                <input
                                    autoFocus
                                    onFocus={(e) => e.target.select()}
                                    maxLength={1}
                                    autoComplete={null}
                                    className={styles.fromInputCode}
                                    type="text"
                                    value={one}
                                    name={"one"}
                                    onChange={(e) => (e.target.value.length < 2 ? setOne(e.target.value) : null)}
                                    onKeyUp={ChangeFocusedInput}
                                />
                                <input
                                    onFocus={(e) => e.target.select()}
                                    maxLength={1}
                                    autoComplete={null}
                                    className={styles.fromInputCode}
                                    type="text"
                                    name={"two"}
                                    value={two}
                                    onChange={(e) => (e.target.value.length < 2 ? setTwo(e.target.value) : null)}
                                    onKeyUp={ChangeFocusedInput}
                                />
                                <input
                                    onFocus={(e) => e.target.select()}
                                    maxLength={1}
                                    autoComplete={null}
                                    className={styles.fromInputCode}
                                    type="text"
                                    name={"three"}
                                    value={three}
                                    onChange={(e) => (e.target.value.length < 2 ? setTree(e.target.value) : null)}
                                    onKeyUp={ChangeFocusedInput}
                                />
                                <input
                                    onFocus={(e) => e.target.select()}
                                    maxLength={1}
                                    autoComplete={null}
                                    className={styles.fromInputCode}
                                    type="text"
                                    name={"four"}
                                    value={four}
                                    onChange={(e) => (e.target.value.length < 2 ? setFour(e.target.value) : null)}
                                    onKeyUp={(e) => ChangeFocusedInput(e, true)}
                                />
                                {validator.current.message("one", one, "required")}
                                {validator.current.message("two", two, "required")}
                                {validator.current.message("three", three, "required")}
                                {validator.current.message("four", four, "required")}
                            </div>
                        </div>

                        <button id="submitBtn" type="submit" className={styles.formBtn}>
                            تائید کد
                        </button>
                        <div className={styles.sectionFormLink}>

                            <Link to="/userlogin" state={{ phoneNumber: location.state?.phoneNumber }} className={styles.formLink}>
                                ویرایش شماره
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </AuthenticatingLayout>
    );
};

export default VerifyPhone;
