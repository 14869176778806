import http from "./AxiosConfig";
import Toast from "../Components/Common/Toast";
import utf8 from "utf8";

class RestConnectionServer {
    static StartCodeErrorAPI = 1000;
    static Accept = 100;
    static Accept2 = 200;
    static endsession = 1056;

    static ErrorConnection = 400;
    static ErrorURL = 3;

    static CheckResult(result) {
        if (typeof result == "object") {
            return result[0].message;
        }
        return result;
    }

    static ConnectionError(Code, Type) {
        if (Type === this.ErrorConnection) {
            Toast("error", "خطایی از سمت ما رخ داده است، لطفا دوباره تلاش کنید!");
        } else if (Type === this.ErrorURL) {
            Toast("error", "خطایی از سمت ما رخ داده است، لطفا دوباره تلاش کنید!");
        } else if (Code < RestConnectionServer.StartCodeErrorAPI) {
            Toast("error", "خطایی از سمت ما رخ داده است، لطفا دوباره تلاش کنید!");
        } else if (Type === this.endsession) {
            window.open("/", "_self");
            sessionStorage.clear();
        }
    }

    static async Connection_AXIOS_GetJsonOnly(URlString) {
        var result = [0, ""];
        const res = await http.get(URlString);
        return res.data.result;
    }

    static async Connection_AXIOS_GetJson(URlString, Option) {
        var result = [0, ""];
        const res = await http.get(URlString, Option)
        .catch(function (Error) {
            result[0] =Error.response.data.errorCode;
            result[1] = Error.response.data.message;
            result[2] = Error.response.data.body;
            //RestConnectionServer.ConnectionError(result[1], result[0]);
        });
      
        if (result[0] < RestConnectionServer.ErrorConnection) {
            result[0] = res.data.errorCode;
            result[1] = res.data.message;
            result[2] = res.data.body;
            if(res.data.totalPage)
                result[3] = res.data.totalPage;
        }

        return result;
    }

    static async Connection_AXIOS_DeleteJson(URlString, Option) {
        var result = [0, ""];

       
             const res = await http.delete(URlString, Option)
             .catch(function (Error) {
                result[0] =Error.response.data.errorCode;
                result[1] = Error.response.data.message;
                result[2] = Error.response.data.body;
                //RestConnectionServer.ConnectionError(result[1], result[0]);
            });

            if (result[0] < RestConnectionServer.ErrorConnection) {
               
                result[0] = res.data.errorCode;
                result[1] = res.data.message;
                result[2] = res.data.body;

            }
       

        return result;
    }

    static async Connection_AXIOS_UpdateJson(URlString, Body, Option) {
        var result = [0, ""];

        if (Body !== null) {
            const res = await http.put(URlString, Body, Option)
            .catch(function (Error) {
                result[0] =Error.response.data.errorCode;
                result[1] = Error.response.data.message;
                result[2] = Error.response.data.body;
                //RestConnectionServer.ConnectionError(result[1], result[0]);
            });

            if (result[0] < RestConnectionServer.ErrorConnection) {
                console.log(res.data.errorCode)
                result[0] = res.data.errorCode;
                result[1] = res.data.message;
                result[2] = res.data.body;

            }
        } else {
            result = [5, "اطلاعاتی برای ارسال دریافت نشده است", ""];
            RestConnectionServer.ConnectionError(result[1], result[0]);
        }

        return result;
    }

    static async Connection_AXIOS_InsertJson(URlString, Body, Option) {
        var result = [0, ""];

        if (Body !== null) {
             const res = await http.post(URlString, Body, Option)
             .catch(function (Error) {
                result[0] =Error.response.data.errorCode;
                result[1] = Error.response.data.message;
                result[2] = Error.response.data.body;
                //RestConnectionServer.ConnectionError(result[1], result[0]);
            });

            if (result[0] < RestConnectionServer.ErrorConnection) {
                console.log(res.data.errorCode)
                result[0] = res.data.errorCode;
                result[1] = res.data.message;
                result[2] = res.data.body;

            }
        } else {
            result = [5, "اطلاعاتی برای ارسال دریافت نشده است", ""];
            RestConnectionServer.ConnectionError(result[1], result[0]);
        }

        return result;
    }
}

export default RestConnectionServer;
