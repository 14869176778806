import React, { useContext, useEffect, useState } from "react";

import LanguageContext from "../../Context/LanguageContext";

import styles from "../../Styles/Personel/AddNewPersonelModal.module.css";
import Toast from "../Common/Toast";
import { giftclass, ceremony,QuestionsClass ,CermonyQuestionlass} from '../../Connection/Transaction'
import AuthContext from "../../Context/AuthContext";

const AddNewcerQuestionModal = ({ close, cermonyId,usedQuestions }) => {
    const { language } = useContext(LanguageContext);
    const { code, phone } = useContext(AuthContext);
    // ======================================================================

    const [name, setname] = useState("");
    const [count, setcount] = useState("");
    const [indexcount, setindexcount] = useState("");
    const [options, setOptions] = useState();
    const [selectbox, setselectbox] = useState({});
    const [selectList, setselectList] = useState([]);
    const [templatedata, settemplatedata] = useState([]);
    const [tempsearch, settempsearch] = useState([]);

    // ======================================================================

    const handleSubmitForm = (e) => {
        e.preventDefault();
        const Listsend = [];
        selectList.forEach(element => {
            Listsend.push({id:element})

        });
        CermonyQuestionlass.postdata(Listsend,cermonyId, phone, code).then(res => {
            if (res[0]) {
                Toast("success", res[1]);
                close();
            }
        })
    };

    // ======================================================================

    useEffect(() => {
        QuestionsClass.Getall(phone,code).then(res=>{
            if(res[0]){
               var filterquestions= res[2].filter(q => !usedQuestions?.map(d=>d.id).includes(q.id))
               
                settemplatedata(filterquestions)
            }
            else
            Toast("error",res[1]);
        })
    }, []);



    const selectQuestion = (val) => {
        if (selectList.includes(val)) {
            setselectList(selectList.filter(item => item !== val))

        }
        else
            setselectList(selectList => [...selectList, val]);
    }
    const serchinQuestins = (val) => {
        settempsearch(templatedata.filter(templatedata => templatedata.title.includes(val)));

    }

    // ======================================================================

    return (
        <div className="modal-wrapper">
            <div className="close-model-div" onClick={close}></div>
            <div className={styles.modalAdd}>
                <div className={styles.informationContainer}>
                    <div className={styles.titleContainer}>
                        <img src="/imgs/Page/Personel/user.svg" />
                        <p>{language.newItemTitle.replace("{name}", "سوالات")}</p>
                    </div>
                    <div className={styles.inputGroup}>
                        <input type="text" placeholder="جست و جو در سوالات" onKeyUp={(e) => serchinQuestins(e.target.value)} style={{ width: "80%" }} />
                    </div>
                    {tempsearch.length > 0 ? (
                        tempsearch.map((index) => (
                            <div className={styles.inputGroup}>
                                <label htmlFor="design_group"> امتیاز :{index.score} </label>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <input type="text" disabled value={index.title} style={{ width: "80%" }} />
                                    {
                                        <button className={selectList?.includes(index.id.toString()) ? styles.ButtonStyleSelect : styles.ButtonStyle} value={index.id}
                                            onClick={(e) => selectQuestion(e.target.value)}
                                        >{selectList?.includes(index.id.toString()) ? "حذف" : "انتخاب"}</button>
                                    }
                                </div>
                                <hr style={{ width: "100%", margin: "5px" }} />

                            </div>
                        ))

                    ) : (

                        templatedata.map((index) => (
                            <div className={styles.inputGroup}>
                                <label htmlFor="design_group"> امتیاز :{index.score} </label>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <input type="text" disabled value={index.title} style={{ width: "80%" }} />
                                    {
                                        <button className={selectList?.includes(index.id.toString()) ? styles.ButtonStyleSelect : styles.ButtonStyle} value={index.id}
                                            onClick={(e) => selectQuestion(e.target.value)}
                                        >{selectList?.includes(index.id.toString()) ? "حذف" : "انتخاب"}</button>
                                    }
                                </div>
                                <hr style={{ width: "100%", margin: "5px" }} />

                            </div>
                        ))

                    )
                    }
                </div>
                <div className={styles.btnContainer}>
                    <button className={styles.closeBtn} type="button" onClick={close}>
                        {language.cancle}
                    </button>
                    <button className={styles.confirmBtn} type="submit" onClick={(e)=>handleSubmitForm(e)}>
                        {language.save}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddNewcerQuestionModal;
