import React, { useContext, useEffect, useState } from "react";

import styles from "../Styles/Personel/Personel.module.css";
import Header from "../Components/Common/Header";
import Toast from "../Components/Common/Toast";
import Grid from "../Components/Common/Grid/Grid";
import {userclass} from '../Connection/Transaction'
import AuthContext from "../Context/AuthContext";
const Users = (props) => {
    const { code, phone } = useContext(AuthContext);

    // ======================================================================

    const [personelsList, setPersonelsList] = useState([]);
    const [gridConfig, setGridConfig] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [TotalPage, setTotalPage] = useState(1);
    // ======================================================================

   

    // ======================================================================

    useEffect(() => {
        userclass.Getall(phone,code,1).then(res=>{
            if(res[0]){
                setPersonelsList(res[2])
                if(res[3])
                    setTotalPage(res[3]);
            }
            else
            Toast("error",res[1]);
          
        })
      
        setGridConfig({
            column: [
                {
                    name: "id",
                    title: "ردیف",
                    type: "text",
                    data: "",
                    editable: true,
                    hidden: false,
                    search: true,
                    size: "100px",
                },
                {
                    name: "phone",
                    title: "شماره همراه",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                },
                {
                    name: "name",
                    title: "نام",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "160px",
                },
                {
                    name: "family",
                    title: "نام خانوادگی",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                },
                {
                    name: "tiket",
                    title: "تعداد ثبت نام",
                    type: "text",
                    data: "",
                    editable: false,
                    hidden: false,
                    search: false,
                    size: "150px",
                }
            ],
            setting: {
                edit: false,
                delete: false,
                search: false,
                add: false,
                select: false,
            },
        });
    }, []);
    
    // ======================================================================

    return (
        <div className={styles.wrapper}>
            <Header
                pageTitle="کاربران"
                newItemTitle="کاربر"
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              
            />

            <div className={styles.contentContainer}>
                <Grid
                    phone={phone}
                    code={code}
                    data={personelsList}
                    config={gridConfig}
                    itemInPerPage={20}
                    search={searchValue}
                    TotalPage={TotalPage}
                    setSearch={setSearchValue}
                />
            </div>

        </div>
    );
};

export default Users;
