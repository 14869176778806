import React, { useContext, useEffect, useState } from "react";

import LanguageContext from "../../Context/LanguageContext";

import styles from "../../Styles/Personel/AddNewPersonelModal.module.css";
import Toast from "../Common/Toast";
import { giftclass, ceremony } from '../../Connection/Transaction'
import AuthContext from "../../Context/AuthContext";

const AddNewPersonelModal = ({ close }) => {
    const { language } = useContext(LanguageContext);
    const { code, phone } = useContext(AuthContext);

    // ======================================================================

    const [name, setname] = useState("");
    const [count, setcount] = useState("");
    const [indexcount, setindexcount] = useState("");
    const [options, setOptions] = useState();
    const [selectbox, setselectbox] = useState({});

    // ======================================================================

    const handleSubmitForm = (e) => {
        e.preventDefault();

        const senddata = {
            idCermony: options,
            name: name,
            count: count,
            index: indexcount
        }
        giftclass.postdata(senddata, phone, code).then(res => {
            if (res[0]) {
                Toast("success", res[1]);
                close();
            }
        })
    };

    // ======================================================================

    useEffect(() => {
        ceremony.Getall(phone, code).then(res => {
            if (res[0]) {
                if (res[2].length > 0) {
                    setselectbox(res[2])
                    setOptions(res[2][0].id)
                }
            }
            else
                Toast("error", res[1]);
        })
    }, []);

    // ======================================================================

    return (
        <div className="modal-wrapper">
            <div className="close-model-div" onClick={close}></div>
            <form onSubmit={handleSubmitForm} className={styles.modalAdd}>
                <div className={styles.informationContainer}>
                    <div className={styles.titleContainer}>
                        <img src="/imgs/Page/Personel/user.svg" />
                        <p>{language.newItemTitle.replace("{name}", "جایزه")}</p>
                    </div>
                    <select className={styles.selectBoxmodal}
                        onChange={(e) => setOptions(e.target.value)}>
                        {selectbox.length > 0 ? selectbox.map(x => (<option value={x.id}> {x.name}</option>))
                            : ""
                        }
                    </select>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">{language.name} : </label>
                        <input placeholder="نام جایزه را وارد کنید." type="text" value={name} onChange={(e) => setname(e.target.value)} />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">تعداد: </label>
                        <input placeholder="تعداد جایزه را وارد کنید." type="text" value={count} onChange={(e) => setcount(e.target.value)} />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="design_group">رتبه: </label>
                        <input placeholder="رتیه را وارد کنید." type="text" value={indexcount} onChange={(e) => setindexcount(e.target.value)} />
                    </div>

                </div>
                <div className={styles.btnContainer}>
                    <button className={styles.closeBtn} type="button" onClick={close}>
                        {language.cancle}
                    </button>
                    <button className={styles.confirmBtn} type="submit">
                        {language.save}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddNewPersonelModal;
