import Url from "./Url";
import RestConnectionServer from "./RestConnectionServer";
import axios from "axios";

export class Hadis {

    static async Get() 
    {
        var status;
        await RestConnectionServer.Connection_AXIOS_GetJsonOnly(Url.Hadis).then((result) => 
        {
            status=result;
        });
        return status;
    }
}

export class Register {

    static async Login(PhoneNumber, Code) {
        var Headers = { headers: { phone: PhoneNumber, tiket: Code } };

        var status = [0, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.RegisterUser, { key1: 'value1' }, Headers)
            .then((result) => (status = result))
            .catch((err) => console.log(err));
        return status;
    }
    static async RgisterInfo(data) {
        var status = [0, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.RegisterInfo, data)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;
    }

}

export class UserAnswer {

    static async Login(PhoneNumber) 
    {
        var Headers = { headers: { Phone: PhoneNumber } };

        var status = [0, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.LoginAnswer, { key1: 'value1' }, Headers)
            .then((result) => (status = result))
            .catch((err) => console.log(err));
        return status;
    }

    static async Rgister(data) {
        var status = [0, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.RigesterAnswer, data)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;
    }

    static async Answer(data,PhoneNumber) {
        var Headers = { headers: { Phone: PhoneNumber } };
        var status = [0, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.SendAnswer, data,Headers)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;
    }

}


export class UserLogin {

    static async Login(PhoneNumber) {
        var Headers = { headers: { phone: PhoneNumber} };

        var status = [0, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.UserLogin, { key1: 'value1' }, Headers)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;
    }
    static async VerifyUser(PhoneNumber,Code) {
        var Headers = { headers: { phone: PhoneNumber, code: Code } };

        var status = [0, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.VerifyUser, { key1: 'value1' }, Headers)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;
    }

}

export class adminlogin {

    static async Login(username, pass) {
        var Headers = { headers: { username: username, password: pass } };

        var status = [0, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.adminLogin, { key1: 'value1' }, Headers)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;
    }
}

export class ceremony {

    static async Getall(phone, code) {

        var Headers = { headers: { Username: phone, Password: code } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_GetJson(Url.CermonyGetall, Headers).then((result) => {
            status = TransAction_check.CheckResult(result);
        });
        return status;

    }
    static async GetallByType(phone, code,TypeId) {

        var Headers = { headers: { Username: phone, Password: code,Type: TypeId} };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_GetJson(Url.CermonyGetallByType, Headers).then((result) => {
            status = TransAction_check.CheckResult(result);
        });
        return status;

    }
    static async postdata(datasend) {
        TransAction_check.GetdataSession();
        if (TransAction_check.Username != undefined && TransAction_check.Password != undefined) {
            var Headers = { headers: { Username: TransAction_check.Username, Password: TransAction_check.Password } };
            var status = [false, "", ""];
            await RestConnectionServer.Connection_AXIOS_InsertJson(Url.Cermonypost, datasend, Headers)
                .then((result) => (status = TransAction_check.CheckResult(result)))
                .catch((err) => console.log(err));
            return status;
        } else {
            TransAction_check.Exit();
        }
    }
    static async UpdateData(datasend) {
        TransAction_check.GetdataSession();
        if (TransAction_check.Username != undefined && TransAction_check.Password != undefined) {
            var Headers = { headers: { Username: TransAction_check.Username, Password: TransAction_check.Password } };
            var status = [false, "", ""];
            await RestConnectionServer.Connection_AXIOS_UpdateJson(Url.Cermonyupdate, datasend, Headers)
                .then((result) => (status = TransAction_check.CheckResult(result)))
                .catch((err) => console.log(err));
            return status;
        } else {
            TransAction_check.Exit();
        }
    }
    static async Cermonypostgroup(datasend,type) {
        TransAction_check.GetdataSession();
        if (TransAction_check.Username != undefined && TransAction_check.Password != undefined) {
            var Headers = { headers: { Username: TransAction_check.Username, Password: TransAction_check.Password,ISGroup:type } };
            var status = [false, "", ""];
            await RestConnectionServer.Connection_AXIOS_InsertJson(Url.Cermonypostgroup, datasend, Headers)
                .then((result) => (status = TransAction_check.CheckResult(result)))
                .catch((err) => console.log(err));
            return status;
        } else {
            TransAction_check.Exit();
        }
    }
    static async Deletedata(datasend) {
        TransAction_check.GetdataSession();
        if (TransAction_check.Username != undefined && TransAction_check.Password != undefined) {
            var Headers = { headers: { Username: TransAction_check.Username, Password: TransAction_check.Password, id: datasend } };
            var status = [false, "", ""];
            await RestConnectionServer.Connection_AXIOS_DeleteJson(Url.Cermonydelete, Headers)
                .then((result) => (status = TransAction_check.CheckResult(result)))
                .catch((err) => console.log(err));
            return status;
        } else {
            TransAction_check.Exit();
        }
    }
}

export class userclass {

    static async Getall(phone, code,PageNumber) 
    {   
        if(PageNumber==undefined)
            PageNumber=1;
        var Headers = { headers: { Username: phone, Password: code,PageNumber:PageNumber,RowSize:20 } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_GetJson(Url.PersonGetAll, Headers).then((result) => {
            status = TransAction_check.CheckResult(result);
        });
        return status;
    }
}

export class QuestionsClass{

    static async Getall(phone, code) {

        var Headers = { headers: { Username: phone, Password: code } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_GetJson(Url.GetAllQuestion, Headers).then((result) => {
            status = TransAction_check.CheckResult(result);
        });
        return status;

    }
    static async InserQuestion(datasend) {
        TransAction_check.GetdataSession();
        if (TransAction_check.Username != undefined && TransAction_check.Password != undefined) {
            var Headers = { headers: { Username: TransAction_check.Username, Password: TransAction_check.Password } };
            var status = [false, "", ""];
            await RestConnectionServer.Connection_AXIOS_InsertJson(Url.InsertQuestion, datasend, Headers)
                .then((result) => (status = TransAction_check.CheckResult(result)))
                .catch((err) => console.log(err));
            return status;
        } else {
            TransAction_check.Exit();
        }
    }
    static async UpdateQuestion(datasend) {
        TransAction_check.GetdataSession();
        if (TransAction_check.Username != undefined && TransAction_check.Password != undefined) {
            var Headers = { headers: { Username: TransAction_check.Username, Password: TransAction_check.Password } };
            var status = [false, "", ""];
            await RestConnectionServer.Connection_AXIOS_UpdateJson(Url.UpdateQuestion, datasend, Headers)
                .then((result) => (status = TransAction_check.CheckResult(result)))
                .catch((err) => console.log(err));
            return status;
        } else {
            TransAction_check.Exit();
        }
    }
    static async Deletedata(datasend) {
        TransAction_check.GetdataSession();
        if (TransAction_check.Username != undefined && TransAction_check.Password != undefined) {
            var Headers = { headers: { Username: TransAction_check.Username, Password: TransAction_check.Password, IDQuestion: datasend } };
            var status = [false, "", ""];
            await RestConnectionServer.Connection_AXIOS_DeleteJson(Url.DeleteQuestion, Headers)
                .then((result) => (status = TransAction_check.CheckResult(result)))
                .catch((err) => console.log(err));
            return status;
        } else {
            TransAction_check.Exit();
        }
    }
}

export class lotteryclass {

    static async Getall(user, code) {

        var Headers = { headers: { Username: user, Password: code } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_GetJson(Url.LotteryGetAll, Headers).then((result) => {
            status = TransAction_check.CheckResult(result);
        });
        return status;

    }
    static async postdata(datasend, user, pass) {
        TransAction_check.GetdataSession();

        var Headers = { headers: { Username: user, Password: pass } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.LotteryAdd, datasend, Headers)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;

    }
    static async Deletedata(datasend, user, pass) {
        TransAction_check.GetdataSession();

        var Headers = { headers: { Username: user, Password: pass, id: datasend } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_DeleteJson(Url.LotteryDelete, Headers)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;

    }
}

export class giftclass {

    static async Getall(phone, code,IdCermony) {
        var Headers = { headers: { Username: phone, Password: code,IdCermony:IdCermony } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_GetJson(Url.GiftGetAll, Headers).then((result) => {
            status = TransAction_check.CheckResult(result);
        });
        return status;
    }
    static async postdata(datasend,username,password) {
        var Headers = { headers: { Username: username, Password: password } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.GiftAdd, datasend, Headers)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;

    }
    static async Deletedata(datasend) {

        var Headers = { headers: { Username: TransAction_check.Username, Password: TransAction_check.Password, id: datasend } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_DeleteJson(Url.GiftDelete, Headers)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;
    }
}

export class CermonyQuestionlass {

    static async Getall(phone, code,IdCermony) {
        var Headers = { headers: { Username: phone, Password: code,IdCermony:IdCermony } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_GetJson(Url.GetAllCeremonyQuestion, Headers).then((result) => {
            status = TransAction_check.CheckResult(result);
        });
        return status;
    }
    static async postdata(datasend,Idcermony,username,password) {
        var Headers = { headers: { Username: username, Password: password,IDCermony: Idcermony} };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.AddCermonyQuestion, datasend, Headers)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;

    }
    static async Deletedata(cermonyId,QuestionId) {

        var Headers = { headers: { Username: TransAction_check.Username, Password: TransAction_check.Password, IDCermony: cermonyId,IDQuestion: QuestionId} };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_DeleteJson(Url.DeleteCermonyQuestion, Headers)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;
    }
}


export class winnerclass {

    static async Getall(phone, code,IdCermony) {
        var Headers = { headers: { Username: phone, Password: code,IdCermony:IdCermony } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_GetJson(Url.winerGetAll, Headers).then((result) => {
            status = TransAction_check.CheckResult(result);
        });
        return status;
    }
    static async GetWinners(phone, code,IdCermony) {
        var Headers = { headers: { Username: phone, Password: code,IdCermony:IdCermony } };
        var status = [false, "", ""];
        await RestConnectionServer.Connection_AXIOS_GetJson(Url.WinneVin, Headers).then((result) => {
          
             status = TransAction_check.CheckResult(result);
        });
        return status;
    }
    
}

/******************************************************************* */

export class GetData {
    static Username = sessionStorage.getItem("Username");
    static Password = sessionStorage.getItem("Password");

    static async Login(username, password) {
        var Body = [{ username, password }];
        var status = [0, "", ""];
        await RestConnectionServer.Connection_AXIOS_InsertJson(Url.Signup_Users, Body)
            .then((result) => (status = TransAction_check.CheckResult(result)))
            .catch((err) => console.log(err));
        return status;
    }

    static async Get_Menu() {
        TransAction_check.GetdataSession();
        if (TransAction_check.Phone != undefined && TransAction_check.Code != undefined) {
            var Headers = { headers: { Phone: TransAction_check.Phone, Code: TransAction_check.Code, Page: Url.MenuItem } };
            var status = [false, "", ""];
            await RestConnectionServer.Connection_AXIOS_GetJson(Url.Get_Main, Headers).then((result) => {
                status = TransAction_check.CheckResult(result);
            });
            return status;
        } else {
            TransAction_check.Exit();
        }
    }
}

export class TransAction_check {
    static Username = sessionStorage.getItem("Username");
    static Password = sessionStorage.getItem("Password");

    static GetdataSession() {
        this.Username = sessionStorage.getItem("Username");
        this.Password = sessionStorage.getItem("Password");
    }

    static CheckResult(result) {
        if (result[0] <= 200)
            result[0] = true;
        else
            result[0] = false;
        return result;
    }

    static Exit() {
        sessionStorage.clear();
        window.open("/login", "_self");
    }

    static StatusData() {
        if (!sessionStorage.getItem("Username") || !sessionStorage.getItem("Password")) {
            window.open("/login", "_self");
        }
    }

    static UserExist(result) {
        if (result[1] == "User not exist") TransAction_check.Exit();
    }
}

export class Transaction_ScreenGroup {
    static async Get() {
        TransAction_check.GetdataSession();
        if (TransAction_check.Username != undefined && TransAction_check.Password != undefined) {
            var Headers = { headers: { Username: TransAction_check.Username, Password: TransAction_check.Password, Page: Url.ScreenGroup } };
            var status = [false, "", ""];
            await RestConnectionServer.Connection_AXIOS_GetJson(Url.Get_Main, Headers).then((result) => {
                status = TransAction_check.CheckResult(result);
            });
            return status;
        } else {
            TransAction_check.Exit();
        }
    }
}
