import React, { useContext } from "react";
import MenuContext from "../../Context/MenuContext";

import styles from "../../Styles/Common/MobileNavbar.module.css"

const MobileNavbar = ({ isOpen, setIsOpen, pageTitle }) => {
    return (
        <div className={styles.container}>
            <div
                className={styles.menuIconWrapper}
                onClick={() => setIsOpen((prevState) => !prevState)}
            >
                <div className={`${styles.hamburger} ${isOpen ? styles.isActive : ""}`}>
                    <span className={styles.line}></span>
                    <span className={styles.line}></span>
                    <span className={styles.line}></span>
                </div>
            </div>
            <h1 className={styles.pageTitle}>{pageTitle}</h1>
            <img
                className={styles.containerLogoImg}
                src="imgs/Menu/Logo.png"
            />
        </div>
    )
}

export default MobileNavbar;