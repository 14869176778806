import React, { } from "react";

import styles from "../../../Styles/Common/Selected.module.css";

const Selected = ({ setting, Initialize, DatePicker, persian, persianFa, DataNewFilter, deleteFilters }) => {
    return (
        <>
            <th className={styles.tableSelectedHeadTrTdCansel}>
                <div className={styles.tableSelectedHeadTrTdInsideOption}>
                    <img src="imgs/eraser.png" onClick={() => deleteFilters(setting)} />
                </div>
            </th>
            <th className={styles.tableSelectedHeadTrTdAccept}>
                <div className={styles.tableSelectedHeadTrTdInsideOption}>
                    <img src="imgs/filter.png" />
                </div>
            </th>
            {setting.column?.map((Item) =>
                Item.hidden == undefined || Item.hidden == false ? (
                    <th
                        className={`${styles.tableSelectedMap} ${Item.type == "image" ? styles.selectedImg : ""}`}
                        style={Item?.size ? { minWidth: `${Item.size}`, maxWidth: `${Item.size}` } : { minWidth: "150px", maxWidth: "150px" }}
                    >
                        {Item.type == "image" ? <div className={styles.tableSelectedMapImg}></div> : null}
                        {Item.type == "text" ? (
                            <div className={styles.tableSelectedMapInside}>
                                <input
                                    type="text"
                                    className={styles.tableSelectedMapInsideInput}
                                    value={Initialize[Item.name]}
                                    onChange={(e) => DataNewFilter(Item.name, e.target.value)}
                                />
                            </div>
                        ) : null}
                        {Item.type == "number" ? (
                            <div className={styles.tableSelectedMapInside}>
                                <input
                                    type="tel"
                                    className={styles.tableSelectedMapInsideInput}
                                    value={Initialize[Item.name]}
                                    onChange={(e) => DataNewFilter(Item.name, e.target.value.replace(/\D/g, ""))}
                                />
                            </div>
                        ) : null}
                        {Item.type == "check" ? <div className={styles.tableHeadTrTdInsideChekBox}></div> : null}
                        {Item.type == "date" ? (
                            <div className={styles.Calender}>
                                <DatePicker
                                    value={Initialize[Item.name] == "" ? " " : Initialize[Item.name]}
                                    onChange={(e) => DataNewFilter(Item.name, e.year)}
                                    calendar={persian}
                                    locale={persianFa}
                                />
                                {/* (e.year + "\\" + e.month + "\\" + e.day) */}
                                {/* {Initialize[Item.name] == ""? <div className={styles.inputDisabled}></div> : null} */}
                            </div>
                        ) : null}
                        {Item.type == "time" ? (
                            <input
                                className={styles.updatingTime}
                                id="appt-time"
                                type="time"
                                name="appt-time"
                                value={Initialize[Item.name]}
                                onChange={(e) => DataNewFilter(Item.name, e.target.value)}
                            />
                        ) : null}
                    </th>
                ) : null
            )}
        </>
    );
};

export default Selected;
