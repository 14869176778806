import React, { useState } from "react";

import styles from "../../../Styles/Common/Row.module.css";

import Combobox from "../Grid/Combobox";

const Row = ({
    deleteFunction,
    setting,
    dataItem,
    indexrow,
    DatePicker,
    persian,
    persianFa,
    submitChanged,
    checkUpdating,
    setCheckUpdating,
    questionFunction
}) => {
    const [initialData, setInitialData] = useState(dataItem);

    const [updating, setUpdating] = useState(false);

    const CheckEdit = () => {
        if (checkUpdating == true) {
            setCheckUpdating(false);
            setUpdating(true);
        } else if (checkUpdating == false) {
            setUpdating(false);
        }
    };

    const handleChange = (trash, key, value) => {
        const tempRow = { ...initialData };
        tempRow[key] = value;
        setInitialData(tempRow);
    };

    const handelSubmitCancel = () => {
        setInitialData(dataItem);
        setUpdating(false);
        setCheckUpdating(true);
    };

    return (
        <>
            {setting.column?.map((Item) =>
                !Item.hidden ? (
                    <td
                        className={`${styles.tableHeadTrTdMap} ${Item.type == "image" ? styles.tableHeadTrTd2 : ""}`}
                        style={Item?.size ? { minWidth: `${Item.size}`, maxWidth: `${Item.size}` } : { minWidth: "150px", maxWidth: "150px" }}
                    >
                        {Item.type == "image" ? (
                            updating == true ? (
                                <div className={styles.updatingImg}>
                                    <label>
                                        <div className={styles.tableHeadTrTdInsideImgJson}>
                                            <img className={styles.updatingImg} src={initialData[Item.name]} />
                                        </div>
                                        <input
                                            type="file"
                                            accept=".png,.svg,.jpg"
                                            onChange={(e) => handleChange(indexrow, Item.name, URL.createObjectURL(e.target.files[0]))}
                                            hidden
                                        />
                                    </label>
                                </div>
                            ) : (
                                <div className={styles.tableHeadTrTdInsideImgJson}>
                                    {initialData[Item.name] ? (
                                        <img src={initialData[Item.name]} />
                                    ) : (
                                        <div
                                            className={styles.tableHeadTrTdInsideImgJsonNull}
                                            style={{ backgroundColor: "#" + Math.floor(Math.random() * 16777215).toString(16) }}
                                        ></div>
                                    )}
                                </div>
                            )
                        ) : null}
                        {Item.type == "text" ? (
                            updating == true ? (
                                <div className={styles.tableHeadTrTdInside}>
                                    <input
                                        type="text"
                                        className={styles.tableHeadTrTdInsideInput}
                                        value={initialData[Item.name]}
                                        onChange={(e) => handleChange(indexrow, Item.name, e.target.value)}
                                    />
                                </div>
                            ) : (
                                <p className={styles.tableHeadTrTdInsideTextType}>{initialData[Item.name]}</p>
                            )
                        ) : null}
                        {Item.type == "number" ? (
                            updating == true ? (
                                <div className={styles.tableHeadTrTdInside}>
                                    <input
                                        type="number"
                                        className={styles.tableHeadTrTdInsideInput}
                                        value={initialData[Item.name]}
                                        onChange={(e) => handleChange(indexrow, Item.name, e.target.value.replace(/\D/g, ""))}
                                    />
                                </div>
                            ) : (
                                <div className={styles.tableHeadTrTdInside}>
                                    <p>{initialData[Item.name]}</p>
                                </div>
                            )
                        ) : null}
                        {Item.type == "phone" ? (
                            updating == true ? (
                                <div className={styles.tableHeadTrTdInside}>
                                    <input
                                        type="tel"
                                        className={styles.tableHeadTrTdInsideInput}
                                        value={initialData[Item.name]}
                                        onChange={(e) => handleChange(indexrow, Item.name, e.target.value.replace(/\D/g, ""))}
                                    />
                                </div>
                            ) : (
                                <div className={styles.tableHeadTrTdInside}>
                                    <p>{initialData[Item.name]}</p>
                                </div>
                            )
                        ) : null}
                        {Item.type == "check" ? (
                            <div className={styles.tableHeadTrTdInsideChekBox}>
                                <input
                                    type="checkbox"
                                    checked={initialData[Item.name]}
                                    onChange={(e) => handleChange(indexrow, Item.name, e.target.checked)}
                                    disabled={updating == true ? false : true}
                                />
                            </div>
                        ) : null}
                        {Item.type == "combobox" ? (
                            <div className={`${styles.tableHeadTrTdInsideCombobox} ${updating ? "" : styles.comboBoxUpdating}`}>
                                <Combobox
                                    ListData={Item.data}
                                    DataValue={initialData[Item.name]}
                                    Open={updating}
                                    DataChange={handleChange}
                                    indexrow={indexrow}
                                    ItemName={Item.name}
                                />
                            </div>
                        ) : null}
                        {Item.type == "date" ? (
                            <div className={`${styles.Calender} ${updating == true ? styles.updatingCalender : ""}`}>
                                <DatePicker
                                    value={initialData[Item.name]}
                                    onChange={(e) => handleChange(indexrow, Item.name, e.year + "\\" + e.month + "\\" + e.day)}
                                    calendar={persian}
                                    locale={persianFa}
                                />
                                {updating == true ? null : <div className={styles.inputDisabled}></div>}
                            </div>
                        ) : null}
                        {Item.type == "time" ? (
                            updating == true ? (
                                <input
                                    className={styles.updatingTime}
                                    id="appt-time"
                                    type="time"
                                    name="appt-time"
                                    step="2"
                                    value={initialData[Item.name]}
                                    onChange={(e) => handleChange(indexrow, Item.name, e.target.value)}
                                />
                            ) : (
                                <div className={styles.tableHeadTrTdInside}>
                                    <p>{initialData[Item.name]}</p>
                                </div>
                            )
                        ) : null}
                    </td>
                ) : null
            )}
            {setting.setting?.edit == true ? (
                <td className={`${styles.tableHeadTrTd} ${updating == true ? styles.updateIcon : ""}`}>
                    {updating == true ? (
                        <div className={styles.tableHeadTrTdInsideImgUpdating}>
                            <div className={styles.imgUpdating}>
                                <img src="/imgs/Common/Grid/cross.png" onClick={() => handelSubmitCancel()} />
                            </div>
                            <div className={styles.imgUpdating}>
                                <img
                                    src="/imgs/Common/Grid/checkmark.png"
                                    onClick={() => {
                                        submitChanged(initialData);
                                        setUpdating(false);
                                        setCheckUpdating(true);
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className={styles.tableHeadTrTdInsideImg}>
                            <img src="/imgs/Common/Grid/edit.svg" onClick={() => CheckEdit()} />
                        </div>
                    )}
                </td>
            ) : null}
            {setting.setting?.delete == true ? (
                <td className={styles.tableHeadTrTd}>
                    <div className={styles.tableHeadTrTdInsideImg}>
                        <img src="/imgs/Common/Grid/trash.svg" onClick={() => deleteFunction(dataItem)} />
                    </div>
                </td>
                
                
            ) : null}
             {setting.setting?.question == true ? (
                <td className={styles.tableHeadTrTd}>
                    <div className={styles.tableHeadTrTdInsideImg}>
                        <img src="/imgs/Common/Grid/edit.svg" onClick={() => questionFunction(dataItem)} />
                    </div>
                </td>
                
            ) : null}
        </>
    );
};
export default Row;
