import { toast } from "react-toastify";

const Toast = (type, text, position = "top-right") => {
    return toast(text, {
        position: position,
        autoClose: 2000,
        type: type,
    });
};

export default Toast;
