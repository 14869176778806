import React, { useState, useEffect } from "react";
import { Combobox } from '@headlessui/react'
import styles from "../../../Styles/Common/Combobox.module.css";

const FilterBox = (
    {
        Open,
        indexrow,
        DataValue,
        ListData,
        ItemName,
        DataChange
    }
) => {
    const [selected, setSelected] = useState([]);
    const [query, setQuery] = useState(DataValue);

    useEffect(() => {
        setSelected(ListData);
        if (indexrow != undefined)
            setQuery(ListData.find(value => value.id == DataValue).name);
    }, [Open])

    const filteredPeople =
        query == null ? selected : selected.filter((person) => {
            return person.name.includes(query);
        })

    const handelInput = (person) => {
        if (person) {
            setQuery(person.name);
            if (indexrow != undefined) {
                DataChange(indexrow, ItemName, person.id)
            } else {
                DataChange(ItemName, person.id)
            }
        }
    }

    return (
        <Combobox>
            <Combobox.Button>
                <Combobox.Input
                    value={DataValue == "" ? "" : query}
                    onChange={(e) => setQuery(e.target.value)}
                    disabled />
            </Combobox.Button>
            {Open ? null : <div className={styles.inputDisabled}></div>}
            <Combobox.Options>
                {filteredPeople.length === 0 && query !== '' ? (
                    <div>
                        یافت نشد
                    </div>
                )
                    : (
                        filteredPeople?.map((person) => (
                            <Combobox.Option
                                onClick={() => handelInput(person)}
                                key={person.id}

                                value={person}
                            >
                                {person.name}

                            </Combobox.Option>
                        ))
                    )}
            </Combobox.Options>
            {Open ? <Combobox.Button >
                <div className={styles.tableHeadTrTdInsideComboboxButton}>
                    <img src="imgs/Common/Grid/down-arrow.png" />
                </div>
            </Combobox.Button> : null}
        </Combobox>
    )
}

export default FilterBox;
