import React from "react";

import styles from "../../Styles/Common/Header.module.css";

const Header = ({ pageTitle, newItemTitle, searchValue, setSearchValue, handleNewBtn,handleNewBtn1 }) => {
    return (
        <div className={styles.headerWrapper}>
            <p className={styles.title}>{pageTitle}</p>

            <div className={styles.searchWrapper}>
                <label className={styles.searchLabel}>
                    <img src="imgs/Common/searchIcon.svg" />
                    <hr />
                </label>
                <input
                    className={styles.searchInput}
                    placeholder={`اسم ${newItemTitle} ...`}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </div>

            <button onClick={handleNewBtn} className={styles.newItemButton} style={handleNewBtn ? {} : { opacity: 0, pointerEvents: "none" }}>
                <span>{newItemTitle} جدید</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 21 21" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.811 3.70105C11.1854 3.70105 11.4889 4.00453 11.4889 4.37888L11.4889 15.882C11.4889 16.2564 11.1854 16.5598 10.811 16.5598C10.4367 16.5598 10.1332 16.2564 10.1332 15.882L10.1332 4.37888C10.1332 4.00453 10.4367 3.70105 10.811 3.70105Z"
                        fill="#fff"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.2404 10.1305C17.2404 9.75614 16.9369 9.45266 16.5625 9.45266L5.0594 9.45266C4.68504 9.45267 4.38157 9.75614 4.38157 10.1305C4.38157 10.5048 4.68504 10.8083 5.0594 10.8083L16.5625 10.8083C16.9369 10.8083 17.2404 10.5048 17.2404 10.1305Z"
                        fill="#fff"
                    />
                </svg>
            </button>
            <button onClick={handleNewBtn1} className={styles.newItemButton} style={handleNewBtn1 ? {} : { opacity: 0, pointerEvents: "none" }}>
                <span>مراسم گروهی جدید</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 21 21" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.811 3.70105C11.1854 3.70105 11.4889 4.00453 11.4889 4.37888L11.4889 15.882C11.4889 16.2564 11.1854 16.5598 10.811 16.5598C10.4367 16.5598 10.1332 16.2564 10.1332 15.882L10.1332 4.37888C10.1332 4.00453 10.4367 3.70105 10.811 3.70105Z"
                        fill="#fff"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.2404 10.1305C17.2404 9.75614 16.9369 9.45266 16.5625 9.45266L5.0594 9.45266C4.68504 9.45267 4.38157 9.75614 4.38157 10.1305C4.38157 10.5048 4.68504 10.8083 5.0594 10.8083L16.5625 10.8083C16.9369 10.8083 17.2404 10.5048 17.2404 10.1305Z"
                        fill="#fff"
                    />
                </svg>
            </button>
        </div>
    );
};

export default Header;
