import React, { useState, useRef, useContext, useEffect } from "react";
import { json, useLocation, useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Toast from "../Components/Common/Toast";
import LoadingContext from "../Context/LoadingContext";
import AuthenticatingLayout from "../Container/Layout/AuthenticatingLayout";
import styles from "../Styles/Authenticating/EnterPhone.module.css";
import styleq from "../Styles/Qustionusers/Questionsawnser.module.css";
import { UserAnswer } from '../Connection/Transaction'
import { Wizard, useWizard } from 'react-use-wizard';

const UserAnswerQuestions = () => {
    const { setLoading } = useContext(LoadingContext);
    const navigate = useNavigate();
    const location = useLocation();
    const simpleValidator = useRef(
        new SimpleReactValidator({
            messages: {
                required: "پر کردن این فیلد الزامی می‌باشد!",
                min: "این فیلد حداقل باید شامل 11 کاراکتر باشد.",
                max: "این فیلد حداکثر باید شامل 11 کاراکتر باشد.",
                phone: "شماره تلفن وارد شده معتبر نمی‌باشد.",
            },
            element: (msg) => (
                <div className="validator-error-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width={20} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ff2c56">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
                        />
                    </svg>
                    <span className="validator-error-text">{msg}</span>
                </div>
            ),
        })
    );

    // =======================================================================

    const [, forceUpdate] = useState();
    const [phoneNumber, setPhoneNumber] = useState(location.state?.phoneNumber ? location.state.phoneNumber : "");
    const [countQuestions, setcountQuestions] = useState(location.state?.Answer ? location.state.Answer : []);

    // =======================================================================
    const [curentq, setcurentq] = useState(0);
    const [checkval, setcheckval] = useState([]);

    const changeState = () => 
    {
        let ST=false;
        countQuestions[curentq].answers.forEach(element => 
        {
            if(element.status)
                ST=true;
        });
        if(ST)
        {
            setcurentq((prevCounter) => prevCounter + 1);
        }
        else
        {
            Toast("error", "باید به سوال پاسخ دهید");
        }
    }

    const submitform = () => 
    {
        let ST=false;
        countQuestions[curentq].answers.forEach(element => 
        {
            if(element.status)
                ST=true;
        });
        if(ST)
        {
            setLoading(true);
            let SendAnswer=[];
            countQuestions.forEach(Q => 
            {
                Q.answers.forEach(A => 
                {
                    if(A.status)
                    {
                        SendAnswer.push({"questionsID": Q.id,"answersID": A.id}); 
                    }
                });
            });

            UserAnswer.Answer(SendAnswer,phoneNumber).then(res => 
            {
                console.log(JSON.stringify(SendAnswer))
                if(res[0])
                {
                    if(res[0])
                    {
                        navigate("/LoginAnswer");
                        Toast("success", res[1]);   
                    }
                    else
                    {
                        Toast("error", res[1]);
                    }
                }
                else
                {
                    Toast("error", "از سمت سرور خطایی رخ داده است");
                }
                setLoading(false);
            })
            setLoading(false);
        }
        else
        {
            Toast("error", "باید به سوال پاسخ دهید");
        }
    }

    const [awnsers, setawnsers] = useState([]);
    const changeCheckbox = (QRowe,ARow) => 
    {
        countQuestions[QRowe].answers.forEach(element => {element.status=false;});

        if(countQuestions[QRowe].answers[ARow].status)
            countQuestions[QRowe].answers[ARow].status=false;
        else
            countQuestions[QRowe].answers[ARow].status=true;

        console.log(countQuestions);
        setcountQuestions(countQuestions=>[...countQuestions]);
    }

    useEffect(() => 
    {
        if(countQuestions.length==0)
        {
            navigate("/LoginAnswer");
        }
    }, []);
    
    
    // =======================================================================

    return (
        <AuthenticatingLayout>
            <div className={styles.AuthenticatingHeader}>
            </div>
            <div className={styles.AuthenticatingSection}>
                {countQuestions?.map((item, index) => (
                    <div key={index} className={index === curentq ? styleq.QuestionBoxContainerActive : styleq.QuestionBoxContainer}>
                        <div style={{ width: "100%" }}>
                            <lable>{item.title}</lable>
                        </div>
                        <div style={{ margin: "10px", display: "flex", flexDirection: "column", alignItems: "start" }}>
                            {item?.answers?.map((awns,aindex) => 
                            (<div  onChange={(e) => changeCheckbox(index,aindex)} style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <label>{awns.title}</label>
                                    <input type="checkbox" checked={countQuestions[index].answers[aindex].status} value={awns.id} onChange={(e) => changeCheckbox(index,aindex)} />
                                </div>))}
                        </div>

                    </div>
                ))}
                {
                    curentq === countQuestions?.length - 1 ? 
                    ( <button className={styles.formBtn} onClick={() => submitform()}>ارسال</button>) : 
                    (<button  className={styles.formBtn} onClick={() => changeState()}>سوال بعدی</button>)
                }
            </div>
            <div className={styles.AuthenticatingBottom}>
            </div>
        </AuthenticatingLayout>
    );
};

export default UserAnswerQuestions;
