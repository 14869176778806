import React, { useState } from "react";

import MainLayout from "./Layout/MainLayout";

import Users from "../Page/Users";
import Ceremony from "../Page/ceremony";
import Gifts from "../Page/Gifts";
import Lottery from "../Page/Lottery";
import Winner from "../Page/Winner";
import QuestionsPage from "../Page/Questions";
import CerQuestions from "../Page/CermonyQuestions";

const MasterPage = () => {
    const [page, setPage] = useState(0);

    // ==================================================================================

    const pageList = [ <Ceremony />, <Users />, <Gifts />, <Winner />,<QuestionsPage/>,<CerQuestions/>];

    // ==================================================================================

    return (
        <MainLayout page={page} setPage={setPage}>
            {pageList[page]}
        </MainLayout>
    );
};

export default MasterPage;
