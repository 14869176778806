import React, { useState, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Toast from "../Components/Common/Toast";
import LoadingContext from "../Context/LoadingContext";
import AuthenticatingLayout from "../Container/Layout/AuthenticatingLayout";
import styles from "../Styles/Authenticating/EnterPhone.module.css";
import { UserLogin } from '../Connection/Transaction'

const EnterPhone = () => {
    const { setLoading } = useContext(LoadingContext);
    const navigate = useNavigate();
    const location = useLocation();
    const simpleValidator = useRef(
        new SimpleReactValidator({
            messages: {
                required: "پر کردن این فیلد الزامی می‌باشد!",
                min: "این فیلد حداقل باید شامل 11 کاراکتر باشد.",
                max: "این فیلد حداکثر باید شامل 11 کاراکتر باشد.",
                phone: "شماره تلفن وارد شده معتبر نمی‌باشد.",
            },
            element: (msg) => (
                <div className="validator-error-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width={20} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ff2c56">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
                        />
                    </svg>
                    <span className="validator-error-text">{msg}</span>
                </div>
            ),
        })
    );

    // =======================================================================

    const [, forceUpdate] = useState();
    const [phoneNumber, setPhoneNumber] = useState(location.state?.phoneNumber ? location.state.phoneNumber : "");
    const [code, setcode] = useState(location.state?.code ? location.state.code : "");

    // =======================================================================

    const submitForm = (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            setLoading(true);
            UserLogin.Login(phoneNumber).then(res => {
                if (res[0]) {
                    navigate("/VerifyPhone", { state: { phoneNumber } });
                    Toast("success", "کد با موفقیت ارسال شد!");
                }
                else {
                    Toast("error", res[1]);
                }
                setLoading(false);

            })
        } else {
            Toast("error", "لطفا فرم را به درستی کامل کن!");
            simpleValidator.current.showMessages();
            forceUpdate(1);
            setLoading(false);
        }

    };

    // =======================================================================

    return (
        <AuthenticatingLayout>
            <div className={styles.AuthenticatingHeader}>
            </div>
            <div className={styles.AuthenticatingSection}>
                <div className={styles.AuthenticatingBody}>
                    <form className={styles.form} onSubmit={submitForm} autoComplete={null}>
                        <div className={styles.formSection}>
                            <label htmlFor="phoneNumber" className={styles.formLabel}>
                                شماره همراه خود را وارد کنید
                            </label>
                            <input
                                autoFocus
                                type="tel"
                                name="phoneNumber"
                                className={styles.formInput}
                                value={phoneNumber}
                                autoComplete={null}
                                placeholder="0912xxxxxxx"
                                onChange={(e) => {
                                    setPhoneNumber(e.target.value);
                                    simpleValidator.current.showMessageFor("phoneNumber");
                                }}
                            />
                            {simpleValidator.current.message("phoneNumber", phoneNumber, "required|phone|min:11|max:11")}
                        </div>
                        <button type="submit" className={styles.formBtn}>
                            ارسال کد تائید
                        </button>
                    </form>

                </div>
            </div>
            <div className={styles.AuthenticatingBottom}>
              
                <span className={styles.spantitle}>امام علی علیه السلام: </span>
                <span className={styles.spantitle}>مال اندوزان، با آن كه زنده‌اند، مُرده‌اند و اهل علم، تا دنيا هست زنده‌اند. پيكرهايشان از ميان مى‌رود، امّا يادشان در دلها هست.</span>
                <span className={styles.spanfooter}>نهج البلاغة : الحكمة ۱۴۷</span>
            </div>
        </AuthenticatingLayout>
    );
};

export default EnterPhone;
